<template>
  <UiBaseLayout>
    <ComplexityChecker type="blocker"></ComplexityChecker>

    <template #footer>
      <VersionFooter class="mt-auto" />
    </template>

    <div class="d-flex h-100">
      <b-tabs
        v-model="activeTabIndex"
        pills
        vertical
        class="border-right border-tabs-color m-0 left-tabs pr-0"
        active-nav-item-class="active-left-tab text-primary"
      >
        <b-tab :title="$t('product.versions.releases')" />
        <b-tab :title="$t('product.versions.revisions')" />
      </b-tabs>

      <div class="center-table px-2">
        <UiPaddedArea class="p-0">
          <ProductReleaseIndex
            v-if="activeTabIndex === 0"
            :product="product"
            :releases="releases"
            :is-readonly="isReadOnlyMode"
            :handle-release-creation="createRelease"
            :handle-release-removal="confirmModal"
            :handle-change-status="preChangeStatus"
            :handle-switch-release="
              (row) =>
                switchCurrentActiveRelease(row.id, row.version, product.id)
            "
            :handle-toggle-fallback-release="toggleFallback"
            :handle-lambda-retry="lambdaRetryAndCheck"
          />

          <ProductRevisionIndex
            v-else
            class="mt-3"
            :product="product"
            :revisions="snapshots"
            :pagination="pagination"
            :downloaded-revisions="downloadedSnapshots"
            :handle-revision-download="downloadSnapshot"
            @onFetchSnapshots="
              (queryPaginate) => onFetchSnapshots(queryPaginate)
            "
          />
        </UiPaddedArea>
        <replace-questionnaire-modal
          :product="product"
          :related-products="relatedProducts"
          :questionnaire-release-version="selectedReleaseVersion"
          :visible="questionnaireSubstitutionVisible"
          @modal-close="hideQuestionnaireSubstitutionDialog"
          @substitution-accepted="questionnaireSubstitutionAccepted"
        />
      </div>
    </div>
    <UiDialog
      title="Are you sure to delete this release ?"
      :visible="isVisible"
      width="80%"
      confirm-label="Confirm delete"
      @confirm="removeRelease"
      @close="closeModal"
      @cancel="closeModal"
    >
    </UiDialog>
  </UiBaseLayout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductReleasesMixin from '../components/ProductReleases/ProductReleasesMixin';
import VersionFooter from '../components/VersionFooter.vue';
import ProductReleaseIndex from '../components/ProductReleases/ProductReleaseIndex.vue';
import ProductRevisionIndex from '../components/ProductReleases/ProductRevisionIndex.vue';
import ReplaceQuestionnaireModal from '../components/ReplaceQuestionnaireModal.vue';
import { productTypes } from '../const/product';
import ComplexityChecker from '../components/Product/ComplexityChecker.vue';
import { PublicationStatus } from '@/domain/model/productRelease';

export default {
  name: 'ProductReleases',
  components: {
    ProductRevisionIndex,
    ProductReleaseIndex,
    ReplaceQuestionnaireModal,
    VersionFooter,
    ComplexityChecker
  },
  mixins: [ProductReleasesMixin],
  props: {
    product: { type: Object, required: true }
  },
  data() {
    return {
      questionnaireSubstitutionVisible: false,
      relatedProducts: [],
      selectedReleaseVersion: '',
      activeTabIndex: 0,
      isVisible: false
    };
  },
  computed: {
    ...mapState('uiProductReleases', ['currentTab']),
    ...mapState('productSnapshot', [
      'snapshots',
      'pagination',
      'downloadedSnapshots'
    ]),
    ...mapState('productRelease', ['releases']),
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('auth', ['isGuest']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    activeTab: {
      get() {
        return this.currentTab || 'RELEASES';
      },
      set(activeTab) {
        this.changeTab(activeTab);
      }
    }
  },
  async mounted() {
    await this.fetchProductReleases();
    this.fetchProductSnapshots();
    this.checkForPendingLambdas();
  },
  methods: {
    onFetchSnapshots({ page, count, sort }) {
      this.fetchProductSnapshots({
        page,
        count,
        sort
      });
    },
    ...mapActions('product', ['getRelatedProductsByQuestionnaire']),
    ...mapActions('uiProductReleases', ['changeTab']),
    ...mapActions('productSnapshot', [
      'fetchProductSnapshots',
      'downloadProductSnapshot'
    ]),
    ...mapActions('productRelease', [
      'fetchProductReleases',
      'removeProductRelease',
      'changeProductReleaseStatus',
      'toggleFallbackRelease',
      'lambdaRetry'
    ]),
    removeRelease() {
      this.removeProductRelease({
        productId: this.product.id,
        releaseId: this.selectedReleaseVersion
      });
      this.isVisible = false;
    },
    confirmModal(releaseId2) {
      this.selectedReleaseVersion = releaseId2;
      this.isVisible = true;
    },
    closeModal() {
      this.selectedReleaseVersion = '';
      this.isVisible = false;
    },
    async preChangeStatus(id, status, isFallbackRelease = false) {
      this.changeStatusData = { id, status };
      if (
        status === PublicationStatus.PRODUCTION &&
        [
          productTypes.QUESTIONNAIRE,
          productTypes.SHADOW_QUESTIONNAIRE
        ].includes(this.product.type)
      ) {
        this.selectedReleaseVersion = this.releases.find(
          (r) => r.id === id
        ).versionAsString;
        const currentProductionRelease = this.releases.find(
          (r) => r.publicationStatus === PublicationStatus.PRODUCTION
        );
        const currentProductionId = currentProductionRelease
          ? currentProductionRelease.productId
          : this.product.version.latest;
        this.relatedProducts = await this.getRelatedProductsByQuestionnaire({
          productId: currentProductionId
        });
        if (this.relatedProducts.length > 0) {
          this.showQuestionnaireSubstitutionDialog();
        } else {
          this.changeStatus(id, status, isFallbackRelease);
        }
      } else {
        this.changeStatus(id, status, isFallbackRelease);
      }
    },
    changeStatus(id, status, isFallbackRelease = false) {
      this.changeProductReleaseStatus({
        productId: this.product.id,
        releaseId: id,
        status,
        isFallbackRelease
      });
      this.scheduleLambdaReleasesFetch();
    },
    toggleFallback(productId, releaseId) {
      this.toggleFallbackRelease({
        productId,
        releaseId
      });
    },
    downloadSnapshot(snapshotId) {
      this.downloadProductSnapshot({
        snapshotId
      });
    },
    showQuestionnaireSubstitutionDialog() {
      this.questionnaireSubstitutionVisible = true;
    },
    hideQuestionnaireSubstitutionDialog() {
      this.questionnaireSubstitutionVisible = false;
    },
    questionnaireSubstitutionAccepted() {
      this.hideQuestionnaireSubstitutionDialog();
      this.changeStatus(this.changeStatusData.id, this.changeStatusData.status);
    },
    checkForPendingLambdas() {
      const needReCheck = this.releases.some(
        (r) => r.lambdaPublished && r.lambdaPublished.status === 'Pending'
      );
      if (needReCheck) {
        this.scheduleLambdaReleasesFetch();
      }
    },
    scheduleLambdaReleasesFetch() {
      setTimeout(async () => {
        await this.fetchProductReleases();
        this.checkForPendingLambdas();
      }, 10000);
    },
    async lambdaRetryAndCheck(release) {
      await this.lambdaRetry(release);
      this.scheduleLambdaReleasesFetch();
    }
  }
};
</script>
