<template>
  <UiLoadingArea
    :is-loading="isCreating"
    :message="$t(`product.versions.releaseCreationInProgress`)"
  >
    <div class="ProductReleasesIndex">
      <StickyNotification />
      <UiToolbar>
        <template #side>
          <b-button
            v-if="enableCreateRelease"
            variant="primary"
            @click="$refs.createReleaseDialog.toggle()"
          >
            <span v-if="currentReleaseOrLatest === 'initial'">{{
              $t('product.versions.newReleaseFromCurrentProduct')
            }}</span>
            <span v-else>
              {{ $t('product.versions.newReleaseFromCurrentProduct') }}
              from
              <strong>{{ currentReleaseOrLatest }}</strong>
            </span>
          </b-button>
        </template>
      </UiToolbar>

      <UiTable
        ref="table"
        class="ui_table_product_release mt-3"
        :data="releases"
        :row-class-name="tableRowClassName"
        row-key="id"
        variant="padded"
        @click="edit"
      >
        <!-- Name -->
        <el-table-column
          prop="name"
          min-width="600"
          :label="$t('product.versions.releaseName')"
        >
          <template #default="{ row }">
            <div class="ReleaseName">
              <div>
                <UiTagDoubleIcon :variant="releaseIcon(row)" />
                <UiLabel
                  :variant="
                    row.publicationStatus === 'EXPIRED' ? 'light' : 'bold'
                  "
                  >{{ row.name }}</UiLabel
                >
              </div>
              <div>
                <UiIconTip
                  v-if="row.isFallbackRelease"
                  icon="el-icon-setting"
                  message="This is the fallback release."
                />
              </div>
            </div>
          </template>
        </el-table-column>

        <!-- Name -->
        <el-table-column
          prop="name"
          min-width="100"
          :label="$t('product.versions.version')"
        >
          <template #default="{ row }">
            <div v-if="isInitialProduct(row)">Initial Product</div>
            <div v-else>{{ row.versionAsString }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="name" min-width="120">
          <template #default="{ row }">
            <transition
              enter-active-class="animated fadeIn"
              mode="out-in"
              appear
            >
              <UiButton v-if="rowIsActive(row)" disabled>{{
                $t('product.versions.active')
              }}</UiButton>
            </transition>
          </template>
        </el-table-column>

        <!-- Status -->
        <el-table-column
          prop="status"
          width="180"
          :label="$t('product.versions.releaseStatus')"
        >
          <template #default="{ row, $index }">
            <div v-if="!isInitialProduct(row)" class="statusContainer">
              <UiInlineDropDown
                v-if="
                  isPublicationStatusEditable(row) &&
                  !isInitializationInProgress(row)
                "
                :label="row.publicationStatus"
                :value="row.publicationStatus"
                :variant="publicationStatusVariant(row.publicationStatus)"
                :items="
                  availablePublicationStatus(row.publicationStatus).map(
                    (v) => ({ key: v, label: v })
                  )
                "
                :toggle-editing="isEditing($index)"
                :handle-change="
                  (v) => {
                    editedRow.value.publicationStatus =
                      v !== undefined ? v : null;
                  }
                "
              />
              <el-tooltip
                v-else
                :content="`Only admin can change the status of a ${row.publicationStatus} release`"
              >
                <UiLabel variant="disabled">
                  {{ row.publicationStatus }}
                </UiLabel>
              </el-tooltip>
              <el-tooltip
                v-if="
                  row.lambdaPublished &&
                  row.lambdaPublished.status === 'Pending'
                "
                :content="'The Lambda funtion is being publish'"
              >
                <div class="loading-spinner-animation"></div>
              </el-tooltip>
              <UiIconTip
                v-if="
                  row.lambdaPublished &&
                  row.lambdaPublished.status === 'Published'
                "
                icon="el-icon-success"
                message="Lambda function ready for this release"
              />
              <el-tooltip
                v-if="
                  row.lambdaPublished && row.lambdaPublished.status === 'Failed'
                "
                popper-class="errorTooltip"
                effect="customized"
              >
                <i class="el-icon-warning"></i>
                <template slot="content">
                  <div>
                    <div><b>There was a technical error.</b></div>
                    <div>Please retry publishing the lambda</div>
                    <el-button
                      class="errorButton"
                      @click="handleLambdaRetry(row)"
                      >RETRY</el-button
                    >
                  </div>
                </template>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>

        <!-- Created on -->
        <el-table-column
          prop="createdAt"
          width="160"
          :label="$t('product.versions.releaseCreatedOn')"
        >
          <template #default="{ row }">
            <UiFormattedDate :date="row.createdAt" />
          </template>
        </el-table-column>

        <!-- Last Revision -->
        <el-table-column
          prop="updatedAt"
          width="160"
          :label="$t('product.versions.releaseLastRevision')"
        >
          <template #default="{ row }">
            <UiFormattedDate :date="row.updatedAt" />
          </template>
        </el-table-column>

        <el-table-column width="120">
          <ProductReleaseRowOperations
            v-if="
              !isInitializationInProgress(row) &&
              !(
                isInitialProduct(row) &&
                row.isFallbackRelease &&
                rowIsActive(row)
              )
            "
            slot-scope="{ $index, row }"
            :is-initial-product="isInitialProduct(row)"
            :is-fallback="row.isFallbackRelease"
            :is-editing="isEditing($index)"
            :is-active="rowIsActive(row)"
            :is-expired="isExpired(row)"
            :is-production="isProduction(row)"
            :is-member="isMember()"
            :is-questionnaire="isQuestionnaire"
            :is-read-only="isReadonly"
            :show-lambda-retry="showLambdaRetry(row)"
            class="ui_buttons_operations"
            @command="(command) => handleRowItemCommand(command, $index)"
          />
        </el-table-column>
      </UiTable>
      <CreateReleaseDialog
        ref="createReleaseDialog"
        :active-release="currentReleaseOrLatest"
        :handle-release-creation="handleReleaseCreation"
        :existing-releases="existingReleaseVersions"
      />
    </div>
  </UiLoadingArea>
</template>

<script>
/* eslint-disable import/no-named-as-default-member */
import Product from '@/domain/model/product';
import ProductRelease, {
  PublicationStatus
} from '@/domain/model/productRelease';
import { mapGetters, mapState } from 'vuex';
import { productTypes } from '@/const/product';
import CreateReleaseDialog from './Dialog/CreateReleaseDialog';
import ProductReleaseRowOperations from './ProductReleaseRowOperations';
import ProductSnapshotMixin from './ProductSnapshotMixin';
import StickyNotification from '../StickyNotification';

const publicationStatusVariantMappings = {
  EXPIRED: 'light',
  PRODUCTION: 'default',
  DRAFT: 'default',
  fallback: 'default'
};

export default {
  name: 'ProductReleaseIndex',
  components: {
    CreateReleaseDialog,
    ProductReleaseRowOperations,
    StickyNotification
  },
  mixins: [ProductSnapshotMixin],
  props: {
    product: { type: Object, required: true },
    releases: { type: Array, required: true },
    isReadonly: { type: Boolean, required: true },
    handleReleaseCreation: { type: Function, required: true },
    handleReleaseRemoval: { type: Function, required: true },
    handleChangeStatus: { type: Function, required: true },
    handleSwitchRelease: { type: Function, required: true },
    handleToggleFallbackRelease: { type: Function, required: true },
    handleLambdaRetry: { type: Function, required: true }
  },
  data() {
    return {
      editedRow: null,
      publicationStatus: [
        PublicationStatus.DRAFT,
        PublicationStatus.PRODUCTION,
        PublicationStatus.EXPIRED
      ]
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMember', 'isGuest', 'isOwner']),
    ...mapState('productRelease', ['isCreating']),
    existingReleaseVersions() {
      return this.releases
        .filter((r) => r.label !== undefined)
        .map((r) => ({
          ...r.version,
          label: r.label
        }));
    },
    currentReleaseOrLatest() {
      return this.currentRelease && this.currentRelease.versionAsString !== null
        ? this.currentReleaseVersion
        : 'initial';
    },
    currentRelease() {
      return this.releases.find(this.rowIsActive);
    },
    currentReleaseVersion() {
      const { version } = this.currentRelease;
      const { major, minor, patch } = version;
      return `${major}.${minor}.${patch}`;
    },
    isQuestionnaire() {
      return [
        productTypes.QUESTIONNAIRE,
        productTypes.SHADOW_QUESTIONNAIRE
      ].includes(this.product.type);
    },
    enableCreateRelease() {
      return (
        !this.isCreating &&
        (this.isAdmin(this.product.team.slug) ||
          this.isOwner(this.product.team.slug) ||
          this.isMember(this.product.team.slug))
      );
    }
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.publicationStatus === PublicationStatus.INITIALIZATION) {
        return 'release-initialization';
      }
      return '';
    },
    rowIsActive(row) {
      return (
        row.versionAsString === this.product.version.current ||
        (!row.versionAsString && this.product.version.current === 'initial')
      );
    },
    isExpired({ publicationStatus }) {
      return ProductRelease.isExpired(publicationStatus);
    },
    isProduction({ publicationStatus }) {
      return ProductRelease.isProduction(publicationStatus);
    },
    isInitializationInProgress({ publicationStatus }) {
      return ProductRelease.isInitializationInProgress(publicationStatus);
    },

    isInitialProduct(product) {
      return Product.isInitialProduct(product);
    },
    isPublicationStatusEditable(product) {
      return ProductRelease.isPublicationStatusEditable({
        isAdmin: this.isAdmin(),
        publicationStatus: product.publicationStatus,
        isReadOnly: this.isReadonly
      });
    },

    edit(row) {
      if (this.isInitialProduct(row) || this.isExpired(row)) return;
      this.editedRow = {
        index: this.releases.indexOf(row),
        value: {
          ...row
        }
      };
    },
    editByIndex(index) {
      if (!this.isMember()) return;
      this.edit(this.getRelease(index));
    },
    isEditing(index) {
      return this.editedRow && this.editedRow.index === index;
    },
    cancel() {
      this.editedRow = null;
    },
    publicationStatusVariant(value) {
      return (
        publicationStatusVariantMappings[value] ||
        publicationStatusVariantMappings.fallback
      );
    },
    readonly(row) {
      return row.readonly || !this.isMember();
    },
    showLambdaRetry(row) {
      // This might change in the future taking into account the updated_at timestamp
      return row.lambdaPublished && row.lambdaPublished.status === 'Failed';
    },
    handleRowItemCommand(command, index) {
      const row = this.getRelease(index);
      // @todo Switch/Reset command did not mutate the product state
      if (this.readonly(row) && ['delete'].includes(command)) return;
      const c = {
        edit: () => this.editByIndex(index),
        delete: () => this.handleReleaseRemoval(row.id),
        save: () => this.save(),
        cancel: () => this.cancel(),
        switch: () => this.handleSwitchRelease(row),
        toggleFallbackRelease: () =>
          this.handleToggleFallbackRelease(row.productId, row.id),
        retryLambda: () => this.handleLambdaRetry(row)
      }[command];
      if (!c) throw new Error(`Unknown command ${command}`);
      c();
    },
    save() {
      const currentRelease = this.releases[this.editedRow.index];
      if (
        this.isPublicationStatusEditable(currentRelease) &&
        !this.isInitializationInProgress(currentRelease)
      ) {
        const { id, publicationStatus } = this.editedRow.value;
        this.handleChangeStatus(
          id,
          publicationStatus,
          currentRelease.isFallbackRelease
        );
      }
      this.editedRow = null;
    },
    getRelease(index) {
      const row = this.releases[index];
      if (!row) throw new Error('Invalid row index');
      return row;
    },
    availablePublicationStatus(currentPublicationStatus) {
      if (currentPublicationStatus === PublicationStatus.PRODUCTION) {
        return this.publicationStatus.filter(
          (e) => e !== PublicationStatus.DRAFT
        );
      }
      return this.publicationStatus;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.ui_table_product_release::v-deep {
  .release-initialization {
    cursor: not-allowed;
    pointer-events: none;

    > td {
      cursor: not-allowed;
      pointer-events: none;
      background-color: $color-placehoder;
    }
  }
}

.statusContainer {
  padding-left: 15px;

  .el-tooltip,
  .loading-spinner-animation {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 5px;
  }

  .el-tooltip.el-icon-success,
  .el-tooltip.el-icon-warning {
    font-size: 22px;
    top: 15px;
  }

  .el-icon-success {
    color: green;
  }

  .el-tooltip.el-icon-warning {
    color: #c91432;
  }
}
</style>

<style lang="scss">
.is-customized.errorTooltip {
  background-color: #c91432;
  color: white;
  line-height: 18px;

  .popper__arrow::before,
  .popper__arrow::after {
    color: #c91432;
    border-color: transparent;
  }

  .errorButton {
    background-color: #ffecef;
    color: #c91432;
    font-size: 12px;
    float: right;
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 5px;
    outline: none !important;
  }
}
</style>
