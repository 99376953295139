var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UiDialog',{staticClass:"import-properties-dialog",attrs:{"title":_vm.$t('properties.import-wizard.title'),"visible":_vm.$attrs.visible,"freeze":_vm.freezeAll,"disable-confirm":_vm.freezeConfirm,"request-confirmation":{
    title: _vm.$t(`properties.import-wizard.confirm-title`),
    message: _vm.$t(`properties.import-wizard.confirm-message`)
  },"variant":"large"},on:{"close":function($event){return _vm.onClose()},"cancel":function($event){return _vm.cancelExport()},"confirm":function($event){return _vm.requestImport()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('div',{staticClass:"header_subtitle"},[_vm._v("\n      "+_vm._s(_vm.subTitle)+" "),_c('i',{staticClass:"el-icon-arrow-right"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.$t('properties.import-wizard.subtitle')))])]),_vm._v(" "),_c('UiLoadingArea',{attrs:{"is-loading":_vm.isLoading,"message":_vm.loadingMessage}},[_c('div',{staticClass:"mb-4"},[_c('span',{staticClass:"label_bold"},[_vm._v(_vm._s(_vm.$t(`properties.import-wizard.filename`))+" :")]),_vm._v("\n        "+_vm._s(_vm.importFileName)+"\n      ")]),_vm._v(" "),_c('UiButton',{attrs:{"data-test":"button-clear-definition-list","variant":"secondary","icon":"el-icon-set-up","disabled":_vm.clearDefinitionRequested || _vm.freezeConfirm},on:{"click":_vm.clearDefinitionLists}},[_vm._v("\n        "+_vm._s(_vm.$t('properties.import-wizard.clear-definition-list'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"pt-4"},[(_vm.definitionLists.length === 0)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t(`properties.import-wizard.no-properties-found`)))])]):_vm._e(),_vm._v(" "),(!_vm.isLoading && _vm.definitionLists.length > 0)?_c('UiTable',{ref:"importPropertiesTable",attrs:{"row-key":"name","data":_vm.definitionLists,"default-sort":{ prop: 'fieldname', order: 'descending' },"row-class-name":_vm.getRowClass},on:{"selectionChange":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"expand",fn:function({ row, $index }){return [_c('PropertyMetadata',{attrs:{"default-property-metadata":[
                `displayName`,
                `description`,
                `question`,
                `defaultValue`
              ],"is-read-only":true,"property":_vm.metadata(row, $index),"selectable-terms":() => _vm.selectableTerms(row),"is-dimension":false,"source-language":'en',"target-language":'en',"tooltip":{
                message: _vm.$t(`properties.import-wizard.metadata-readyonly`)
              }}})]}}],null,false,627695549)},[_vm._v("\n          >\n          "),_c('el-table-column',{attrs:{"type":"selection","reserve-selection":"","width":"55"}}),_vm._v(" "),_vm._v(" "),_c('el-table-column',{attrs:{"sortable":"custom","label":"Property"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('UiColorPicker',{attrs:{"toggle-editing":false,"value":row.color,"palette":_vm.colors,"data-cy":`color-picker-${row.dimensionName}`,"data-test":"property-color-picker"}}),_vm._v(" "),_c('span',{attrs:{"data-cy":`dimension-row-${$index}`}},[_vm._v(_vm._s(row.dimensionName))])]}}],null,false,131540215)}),_vm._v(" "),_c('el-table-column',{attrs:{"sortable":"custom","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row.type))])]}}],null,false,1661806940)}),_vm._v(" "),_c('el-table-column',{attrs:{"sortable":"custom","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',{class:{
                  label_itemImported: _vm.isPropertyImported(row),
                  label_itemExist:
                    (row.imported === 'duplicate' ||
                      row.imported === 'tobeoverriden') &&
                    row.selected
                }},[(_vm.isPropertyImported(row))?_c('i',{staticClass:"check-circle far fa-check-circle"}):_vm._e(),_vm._v("\n                "+_vm._s(_vm.getStatus(row)))])]}}],null,false,1247438888)}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Definition list"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === `DIMENSION`)?_c('el-select',{attrs:{"data-cy":`select-definition-list-${scope.$index}`,"data-test":"select-definition-list","placeholder":"Definition list","filterable":""},on:{"change":function($event){return _vm.updateDefinitionList(scope.row)}},model:{value:(scope.row.definitionListId),callback:function ($$v) {_vm.$set(scope.row, "definitionListId", $$v)},expression:"scope.row.definitionListId"}},_vm._l((_vm.definitionListsFormatted),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.name,"value":item._id}})}),1):_vm._e()]}}],null,false,3032004845)})],1):_vm._e()],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }