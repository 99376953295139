/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-nested-ternary */

import moment from 'moment';
import { queryClient } from '../../api/queryClient';
import authStore from '../../store/auth';

const formatToCsv = (data) => {
  return data
    .map((row) =>
      row
        .map(String)
        .map((v) => v.replaceAll('"', '""'))
        .map((v) => `"${v}"`)
        .join(',')
    )
    .join('\r\n');
};

function download(content, filename, contentType) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.click();
}

const partnerPreset = {
  filenamePattern: '{{tenant}}-products-export-{{date}}.csv',
  contentType: 'text/csv;charset=utf-8;',
  formatter: formatToCsv,
  converter: (p) => [
    p.id,
    p.name,
    p.metadata
      ? p.metadata.from_time
        ? moment(new Date(p.metadata.from_time)).format('YYYY-MM-DD')
        : ''
      : '',
    p.metadata
      ? p.metadata.to_time
        ? moment(new Date(p.metadata.to_time)).format('YYYY-MM-DD')
        : ''
      : '',
    p.metadata
      ? p.metadata.travel_before
        ? moment(new Date(p.metadata.travel_before)).format('YYYY-MM-DD')
        : ''
      : ''
  ]
};

const presets = {
  'partners-travel': partnerPreset,
  'partners-travel-r3': partnerPreset,
  'partners-travel-uat': partnerPreset
};

const productExporter = () => [
  {
    name: 'products:exporter',
    desc: 'CSV Product Exporter',
    async get() {
      const startedAt = performance.now();
      const { tenant } = authStore.state;

      let products = [];

      const count = 50;
      let go = true;
      let page = 1;

      if (!presets[tenant]) {
        return `Product export is not supported for the current tenant, duration=${(
          (performance.now() - startedAt) /
          1000
        ).toFixed(3)}s`;
      }

      const { formatter, filenamePattern, contentType, converter } =
        presets[tenant];

      while (go) {
        // eslint-disable-next-line no-await-in-loop
        const result = await queryClient(tenant, 'products', 'all', {
          page,
          count
        });
        go = page * count < result.total;
        page += 1;
        products = [...products, ...result.data.map(converter)];
      }

      download(
        formatter(products),
        filenamePattern
          .replace('{{tenant}}', tenant)
          .replace('{{date}}', moment(new Date()).format('YYYY-MM-DD')),
        contentType
      );

      return `${products.length} product exported, duration=${(
        (performance.now() - startedAt) /
        1000
      ).toFixed(3)}s`;
    }
  }
];

export default productExporter();
