<template>
  <div v-selector.view class="container">
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="my-4">{{ $t('applications') }}</h3>
      <el-checkbox v-if="false" v-model="showBetaApps" :checked="showBetaApps">
        Show apps in beta
      </el-checkbox>
    </div>
    <div class="row">
      <div
        v-for="app in nonWidgetapps"
        :key="app.path"
        class="col-lg-6 d-flex"
        @click="$emit('currentApp', app)"
      >
        <router-link
          v-if="app.component"
          :to="{
            name: `product-app-${app.path}`,
            params: { id: product.id },
            query: $router.currentRoute.query
          }"
          class="card p-4 mb-4 flex-fill"
        >
          <h4 :data-cy="`app-${app.name}`">
            {{ app.name }} <sup v-if="app.isBeta">beta</sup>
          </h4>
          <p>{{ app.description }}</p>
        </router-link>
        <a
          v-if="!app.component"
          target="_blank"
          class="card p-4 mb-4 flex-fill"
          :href="replaceRouteParams(app.path)"
        >
          <h4 :data-cy="`app-${app.name}`">
            {{ app.name }} <sup v-if="app.isBeta">beta</sup>
          </h4>
          <p>{{ app.description }}</p>
        </a>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <h4>{{ $t('widgets') }}</h4>
      <i>{{ $t('widget-explanation') }}</i>
    </div>
    <div class="row">
      <div
        v-for="app in widgetApps"
        :key="app.path"
        class="col-lg-6 d-flex"
        @click="$emit('currentApp', app)"
      >
        <router-link
          :to="{
            name: `product-widget-${app.path}`,
            params: { id: product.id },
            query: $router.currentRoute.query
          }"
          class="card p-4 mb-4 flex-fill"
        >
          <h4>{{ app.name }} <sup v-if="app.isBeta">beta</sup></h4>
          <p>{{ app.description }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import apps from './apps/index';

export default {
  name: 'ProductAppsIndex',
  props: { product: { type: Object, required: true } },
  computed: {
    showBetaApps: {
      get() {
        return this.$route.query.beta === 'true';
      },
      set(value) {
        const { name, params } = this.$route;
        this.$router.replace({
          name,
          params,
          query: value && { beta: 'true' }
        });
      }
    },
    apps() {
      return apps.filter(
        (app) =>
          (this.$route.query.beta ? true : !app.isBeta || app.isVisible) &&
          !this.productIsExcluded(app)
      );
    },
    nonWidgetapps() {
      return this.apps.filter((app) => app.type !== 'widget');
    },
    widgetApps() {
      return this.apps.filter((app) => app.type === 'widget');
    }
  },
  methods: {
    productIsExcluded(app) {
      return app.excludedTypes
        ? app.excludedTypes.includes(this.product.type)
        : false;
    },
    replaceRouteParams(url) {
      return url
        .replace('%tenant%', this.$route.params.tenantSlug)
        .replace('%productId%', this.$route.params.productId)
        .replace('%version%', this.$route.query.version || 'initial');
    }
  }
};
</script>

<style scoped>
sup {
  text-transform: uppercase;
  font-size: 0.5em;
  letter-spacing: 0.1em;
}
</style>
