/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Message } from 'element-ui';
import { colors } from '../config';
import * as api from '../api';
import { sortByFieldname } from '../helpers';

export default {
  data: () => ({
    editedRow: { index: null, value: null },
    isCreationDialogVisible: false,
    colors,
    typeNameToType: {
      'Monetary amount': 'monetary',
      MonetaryAmount: 'monetary',
      Date: 'date',
      DateTime: 'datetime',
      Distance: 'distance',
      Night: 'night',
      Day: 'day',
      Week: 'week',
      Month: 'month',
      Number: 'number',
      Boolean: 'boolean',
      Text: 'text',
      Invalid: 'invalid'
    }
  }),
  methods: {
    showCreationDialog() {
      this.isCreationDialogVisible = true;
    },
    hideCreationDialog() {
      this.isCreationDialogVisible = false;
    },
    exportAllProperties() {
      api.exportSharedProperties(this.tenant);
    },
    expandAllProperties() {
      if (this.$refs.table) this.$refs.table.toggleAllRowsExpansion();
    },
    translateValueType(type) {
      return this.$t(`product.field_type.${this.typeNameToType[type] || type}`);
    },
    stopEditing() {
      this.editedRow = { index: null, value: null };
    },
    filterName(o, prop, value, uppercase = false) {
      const v = this.toValidName(value, uppercase);
      this.$set(o, prop, v);
      return v;
    },
    toValidName(val) {
      let value = val.replace(/[^\w]/gi, '');
      if (this.isDimension && this.isDimension()) value = value.toUpperCase();
      return value;
    },
    isEditing(index) {
      return this.editedRow.index === index;
    },
    async onSort(field, order) {
      try {
        if (order && field) {
          if (order === this.sortOrder && field === this.sortField) {
            return;
          }

          this.sortField = field;
          this.sortOrder = order;

          this.updatePaginatedProperties();
        }
      } catch (e) {
        Message.error(e);
      }
    },
    updatePaginatedProperties() {
      this.propertiesSorted = sortByFieldname(
        this.properties,
        this.sortField,
        this.sortOrder
      ).slice(
        (this.page - 1) * this.count,
        (this.page - 1) * this.count + this.count
      );
    }
  }
};
