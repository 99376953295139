<template>
  <div>
    <UiLoading v-if="isLoading" />
    <div
      v-else
      id="testContainer"
      :class="{
        testContainerCompact: isGraphVisible
      }"
      data-cy="test-container"
    >
      <div id="settings" data-cy="test-settings">
        <UiBaseLayout>
          <template #header>
            <UiToolbar v-if="!isReadOnly">
              <template #side>
                <el-dropdown
                  trigger="click"
                  class="actionsDropdown"
                  data-cy="test-actions"
                  @command="handleGeneralAction"
                >
                  <el-button type="primary">
                    {{ $t('tests.actions')
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      command="fillWithDefaults"
                      data-cy="fill-defaults-values"
                    >
                      {{ $t('tests.default_values') }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      command="resetValues"
                      data-cy="reset-values"
                    >
                      {{ $t('tests.reset') }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <UiButton
                  v-if="!isReadOnly"
                  class="testActionButton"
                  :class="{ createButton: isNewTest, updateButton: !isNewTest }"
                  data-cy="create-update-test"
                  @click="createUpdateTest"
                >
                  {{
                    isNewTest
                      ? $t('tests.create_a_test')
                      : $t('tests.update_test')
                  }}
                </UiButton>
              </template>

              <back-button data-cy="back-button"></back-button>
            </UiToolbar>

            <div class="statusAndName">
              <div
                class="status"
                :class="{
                  success: isTestSucceed,
                  failed: isTestFailed
                }"
                data-cy="status-test-name"
              >
                <input
                  v-if="editingName || testName === ''"
                  ref="nameInput"
                  v-model="testName"
                  type="text"
                  :placeholder="$t('tests.test_name')"
                  data-cy="test-name"
                  @blur="toggleEditingName"
                  @keyup.enter="$event.target.blur()"
                />
                <span v-else data-cy="test-name-action"
                  >{{ testName
                  }}<span @click="toggleEditingName"
                    ><UiPencilIcon v-if="!isReadOnlyMode" /></span
                ></span>
              </div>
            </div>
          </template>

          <div class="assertions">
            <h3 class="sectionTitle">{{ $t('tests.assertions') }}</h3>
            <questions-form
              id="assertionsForm"
              :key="formsKey.assertions"
              :readonly="isReadOnlyMode"
              :questions="formatedAssertions"
              data-cy="assertions-form"
              @answerQuestion="setAssertionExpectedVal"
              @clearQuestion="unsetAssertion"
            ></questions-form>
          </div>

          <div v-if="formatedEndorsements" class="endorsements">
            <h3 class="sectionTitle">{{ $t('product.endorsements') }}</h3>
            <questions-form
              id="endorsementsForm"
              :key="formsKey.endorsements"
              ref="endorsements"
              :readonly="isReadOnlyMode"
              :questions="formatedEndorsements"
              data-cy="endorsements-form"
              @inputShow="endorsementsChange"
            ></questions-form>
          </div>

          <div class="inputs">
            <h3 class="sectionTitle">
              {{ $t('tests.input_variables') }}
            </h3>
            <questions-form
              id="questionsForm"
              :key="formsKey.questions"
              :readonly="isReadOnlyMode"
              :questions="formatedQuestions"
              data-cy="inputs-form"
              @answerQuestion="answerQuestion"
              @clearQuestion="clearQuestion"
            ></questions-form>
          </div>
        </UiBaseLayout>
      </div>

      <div id="results">
        <div
          id="graph"
          :class="{ ['graph-visible']: isGraphVisible }"
          data-cy="graph-container"
        >
          <div class="graph-toggle" @click="toggleRule">
            <div>
              <i v-if="isGraphVisible" class="fas fa-chevron-up"></i>
              <i v-else class="fas fa-chevron-down"></i>
              <span class="graph-toggle-label">{{
                isGraphVisible ? 'Hide the Rule Editor' : 'Show the Rule Editor'
              }}</span>
            </div>
          </div>
          <div class="graph">
            <div v-if="graphIsEmpty && isGraphVisible" class="graph-error">
              <span>{{ $t('tests.no_graph') }}</span>
            </div>
            <div v-else ref="editor" class="graph-wrapper"></div>
          </div>
        </div>
        <div id="outputs">
          <div
            v-if="isTestSet"
            class="status"
            :class="{
              success: isTestSucceed,
              failed: isTestFailed
            }"
            data-cy="test-status"
          >
            Test {{ isTestSucceed ? $t('tests.success') : $t('tests.failed') }}
          </div>
          <div class="assertions">
            <div class="subHeader">
              <div class="title">{{ $t('tests.assertions') }}</div>
              <div class="actions">
                <span data-cy="toggle-json-table" @click="toggleJSONView"
                  ><i class="el-icon-tickets"></i>{{ viewAsText }}</span
                >
              </div>
            </div>

            <UiTable
              v-if="!viewAsJSON"
              :data="formatedResultsData"
              variant="padded"
              class="innerTable"
              data-cy="results-table"
            >
              <el-table-column prop="status" :label="$t('tests.output.status')">
                <template slot-scope="scope">
                  <i
                    v-if="scope.row.status"
                    class="singleTestResult el-icon-circle-check"
                  ></i>
                  <i v-else class="singleTestResult el-icon-circle-close"></i>
                </template>
              </el-table-column>
              <el-table-column
                prop="description"
                :label="$t('tests.output.type')"
              ></el-table-column>
              <el-table-column
                prop="expected"
                :label="$t('tests.output.expected')"
              ></el-table-column>
              <el-table-column
                prop="actual"
                :label="$t('tests.output.output')"
              ></el-table-column>
            </UiTable>

            <textarea
              v-if="viewAsJSON"
              v-model="response"
              class="response response--dark response--large"
              readonly
              data-cy="results-json"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import { Message } from 'element-ui';
import { RuleEditor } from '../components/AnnotationSupport';
// eslint-disable-next-line import/no-cycle
import * as api from '../api';
import { extractDefaultValues } from '../util';
import BackButton from '../components/BackButton.vue';
import QuestionsForm from '../components/ProductTests/QuestionsForm.vue';
import { displayName } from '../helpers';

export default {
  name: 'ProductTestsEditor',
  components: {
    BackButton,
    QuestionsForm
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      testToUse: null,
      dimensions: null,
      testId: undefined,
      copyTestId: undefined,
      testName: '',
      editingName: false,
      selected: {},
      response: {},
      isNewTest: false,
      isCopyTest: false,
      viewAsJSON: false,
      formatedQuestions: [],
      formsKey: {
        assertions: 1,
        endorsements: 1,
        questions: 1
      },
      fullQuestions: [],
      assertions: {
        cover: false,
        couldNotResolve: false,
        isExclusion: false,
        isExtra: false,
        globalLimit: 'None',
        limits: false
      },
      results: {
        cover: false,
        couldNotResolve: false,
        isExclusion: false,
        isExtra: false,
        globalLimit: 'None',
        limits: false
      },
      shouldTest: {
        cover: true,
        couldNotResolve: true,
        isExclusion: true,
        isExtra: true,
        globalLimit: false,
        limits: false
      },
      selectedEndorsements: [],
      graphAvailable: true,
      isGraphLoading: false,
      isGraphVisible: false,
      graphIsEmpty: false
    };
  },
  computed: {
    ...mapGetters('product', ['isReadOnly', 'usedInput', 'usedDimensions']),
    ...mapGetters('productProperty', ['endorsements']),
    ...mapGetters('productTests', ['getTest']),
    ...mapState('productTests', ['tests', 'graph']),
    ...mapGetters('auth', ['isGuest']),
    ...mapState('route', {
      versionId: (state) => state.query.v
    }),
    showGraph() {
      return this.isGraphVisible && this.isGraphLoading === false;
    },
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    inputs() {
      return _.map(this.product.specification.in, (obj, key) => ({
        technicalName: key,
        ...obj
      }));
    },
    getInputToAsk() {
      if (!this.fullQuestions || !this.fullQuestions.length) {
        return this.usedInput;
      }
      return this.fullQuestions
        .filter((input) => input.inputName !== undefined)
        .map((input) => {
          const spec =
            this.product.specification.in &&
            this.product.specification.in[input.inputName];
          return {
            technicalName: input.inputName,
            name: input.displayName || input.name,
            __type: spec.__TYPE || 'INPUT',
            type: spec.type || 'Number',
            metadata: spec.metadata || {}
          };
        });
    },
    getDimensionToAsk() {
      if (!this.fullQuestions || !this.fullQuestions.length) {
        return this.dimensions;
      }
      const coverageDimensions = this.fullQuestions
        .filter((e) => e.dimensionName !== undefined)
        .map((dimension) => dimension.dimensionName);

      return this.dimensions.filter((dimension) =>
        coverageDimensions.includes(dimension.type)
      );
    },
    formatedAssertions() {
      const toAsk = Object.keys(this.shouldTest).filter((assertion) => {
        return this.shouldTest[assertion];
      });
      const assertions = toAsk.map((assertion) => {
        let value;
        if (this.isNewTest && !this.isCopyTest) {
          value = undefined;
        } else if (typeof this.assertions[assertion] !== 'undefined') {
          value = this.assertions[assertion].toString();
        } else {
          value = undefined;
        }
        const snakeAssertion = assertion.replace(
          /[A-Z]/g,
          (letter) => `_${letter.toLowerCase()}`
        );
        return {
          id: assertion,
          type: 'boolean',
          required: false,
          metadata: [
            {
              key: 'displayName',
              value: this.$t(`tests.${snakeAssertion}`)
            }
          ],
          answers: [],
          possibleAnswers: [],
          defaultValue: value,
          priority: 0
        };
      });
      return assertions;
    },
    formatedEndorsements() {
      if (!this.endorsements.length) return false;

      const specEndorsements = this.product.specification.endorsements;
      const answers = this.endorsements.map((en) => {
        const label =
          typeof specEndorsements[en.name].metadata.displayName === 'string'
            ? specEndorsements[en.name].metadata.displayName
            : en.name;
        return {
          id: en.name,
          displayName: label,
          metadata: [
            {
              key: 'displayName',
              value: label
            }
          ]
        };
      });
      return [
        {
          id: 'endorsements',
          type: 'enum',
          required: false,
          answers,
          possibleAnswers: this.endorsements.map((en) => en.name),
          defaultValue: this.selectedEndorsements,
          allowMultiplePerClaim: true,
          priority: 0
        }
      ];
    },
    formatedResultsData() {
      const data = [];
      Object.entries(this.shouldTest).forEach((test) => {
        if (test[1] && this.assertions[test[0]] !== undefined) {
          data.push({
            status: this.assertions[test[0]] === this.results[test[0]],
            description: test[0],
            expected: `${this.assertions[test[0]]}`,
            actual: `${this.results[test[0]]}`
          });
        }
      });
      return data;
    },
    viewAsText() {
      return this.viewAsJSON
        ? this.$t('tests.view_table')
        : this.$t('tests.view_json');
    },
    isTestSucceed() {
      let fails = 0;
      Object.entries(this.shouldTest).forEach((test) => {
        if (
          test[1] &&
          this.assertions[test[0]] !== undefined &&
          this.assertions[test[0]] !== this.results[test[0]]
        ) {
          fails += 1;
        }
      });
      return fails === 0;
    },
    isTestFailed() {
      return !this.isTestSucceed;
    },
    isTestSet() {
      let settedAssertions = 0;
      Object.entries(this.shouldTest).forEach((test) => {
        if (test[1] && this.assertions[test[0]] !== undefined) {
          settedAssertions += 1;
        }
      });
      return settedAssertions > 0;
    }
  },
  watch: {
    async testToUse() {
      if (this.testToUse.id) {
        this.populateForm(this.testToUse);
      }
      await this.getQuestionsByCoverage();
      await this.checkCoverage();
      this.reRenderForms();
    },
    fullQuestions() {
      this.setFormatedQuestions();
    }
  },
  async mounted() {
    await this.fetchProductProperties(this.product.id);
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (this.$route.params.testId) {
      if (this.$route.params.testId === 'new') {
        this.isNewTest = true;
        const { copy } = this.$route.query;
        if (copy) {
          this.isCopyTest = true;
          this.copyTestId = Number.parseInt(copy, 10);
          this.testToUse = this.getTest(this.copyTestId);
        } else {
          this.testToUse = {};
          this.resetForm();
        }
      } else {
        this.testId = Number.parseInt(this.$route.params.testId, 10);
        this.testToUse = this.getTest(this.testId);
      }
      this.checkQueryAnswers();
    }
  },
  methods: {
    ...mapActions('productTests', ['fetch']),
    ...mapActions('productProperty', ['fetchProductProperties']),
    setFormatedQuestions() {
      const data = [];
      const inputs = this.getInputToAsk;
      inputs.forEach((input) => {
        let value = this.selected[input.technicalName];
        if (!value) {
          value = input.metadata.defaultValue;
          this.selected[input.technicalName] = value;
        }
        data.push({
          id: input.technicalName,
          type: input.type.toLowerCase(),
          required: false,
          metadata: [
            {
              key: 'displayName',
              value: this.getDisplayName(input)
            },
            { key: 'defaultValue', value }
          ],
          answers: [],
          possibleAnswers: [],
          defaultValue: value,
          priority: 0
        });
      });

      const dimensions = this.getDimensionToAsk;
      dimensions.forEach((d) => {
        const prodDimension = this.product.specification.dimensions[d.type];
        const question = this.fullQuestions.find(
          (q) => q.dimensionName === d.type
        );
        if (question) {
          const answers = question.dimensionValues
            .filter((answer) => answer !== null)
            .map((answer) => ({
              id: answer.primaryKey,
              displayName: answer.displayName,
              metadata: [{ key: 'displayName', value: answer.displayName }]
            }));
          let value = this.selected[d.type];
          if (!value) {
            value = prodDimension.metadata.defaultValue;
            this.selected[d.type] = value;
          }
          data.push({
            id: d.type,
            type: prodDimension.type.toLowerCase(),
            text: d.type,
            displayName: d.type,
            required: false,
            answers,
            possibleAnswers: answers.map((a) => a.primaryKey),
            defaultValue: value,
            priority: 0,
            allowMultiplePerClaim: false,
            metadata: [
              {
                key: 'displayName',
                value: this.getDisplayName(prodDimension)
              },
              { key: 'defaultValue', value }
            ]
          });
        }
      });
      const result = data.sort((a, b) => {
        const noCaseA = a.metadata
          .find((md) => md.key === 'displayName')
          .value.toLowerCase();
        const noCaseB = b.metadata
          .find((md) => md.key === 'displayName')
          .value.toLowerCase();
        if (noCaseA < noCaseB) return -1;
        if (noCaseA > noCaseB) return 1;
        return 0;
      });
      this.formatedQuestions = result;
    },
    async getQuestionsByCoverage() {
      const query = { endorsements: this.selectedEndorsements };
      const { data } = await api.executeAPIQuery(
        'cover',
        this.product.id,
        query,
        this.versionId
      );
      // only update dimension on first call
      if (!this.dimensions) {
        // @TODO Fix this with a new API that returns all values
        // with no need of all this mapping.
        const productDimensions = this.usedDimensions;
        const { terms } = this.product;

        this.dimensions = Object.keys(productDimensions).map((i) => {
          const dim = productDimensions[i];

          const term = terms[i]
            ? terms[i]
                .filter((t) => Object.keys(dim).includes(t.name))
                .reduce(
                  (a, t) => [
                    ...a,
                    ...t.definitions.map((d) => {
                      return d.primaryKey;
                    })
                  ],
                  []
                )
            : [];

          const filteredQuestions = data.question
            ? data.questions.filter((q) => q.type === i)
            : [];
          const question =
            data.questions && filteredQuestions.length
              ? filteredQuestions[0]
              : {
                  answers: []
                };

          const { dimensions } = this.product.specification;
          const defaultValue = extractDefaultValues(dimensions).filter(
            (dv) => dv.type === i
          );

          return {
            type: i,
            answers: [
              ...new Set([
                ...Object.keys(dim).map((d) => dim[d].value),
                ...term,
                ...question.answers,
                ...defaultValue.map((dv) => dv.value)
              ])
            ].sort()
          };
        });
      }
      this.fullQuestions = data.questions;
    },
    async checkCoverage() {
      const query = this.createQuery();
      const { data } = await api.executeAPIQuery(
        'cover',
        this.product.id,
        query,
        this.versionId
      );
      this.response = JSON.stringify(data, null, 2);
      this.renderRule();
      this.results = {
        cover: data.cover,
        isExclusion: data.isExclusion,
        couldNotResolve: data.couldNotResolve,
        isExtra: data.isExtra || false,
        globalLimit: (data.limits && data.limits.target === 'All') || 'None',
        limits: JSON.stringify(data.rawLimits, null, 2) || ''
      };
      this.isLoading = false;
      if (this.isNewTest && !this.testName) {
        this.$nextTick(() => {
          this.$refs.nameInput.focus();
        });
      }
    },
    createQuery() {
      const query = {};
      Object.keys(this.selected).forEach((key) => {
        if (_.find(this.inputs, { technicalName: key })) {
          if (!query.input) {
            query.input = {};
          }
          if (
            this.selected[key] &&
            this.selected[key].toString().trim() !== ''
          ) {
            query.input[key] = this.selected[key];
          }
        } else if (
          typeof this.selected[key] === 'string' &&
          this.selected[key].trim().length
        ) {
          query[key] = {
            is: this.selected[key]
          };
        }
      });
      query.input = _.omitBy(query.input, _.isNil);
      query.endorsements = this.selectedEndorsements;
      return query;
    },
    populateForm(item) {
      if (item.name && item.definition) {
        this.testName = item.name;
        this.selected = _.clone(item.definition.input);
        this.selectedEndorsements = item.definition.endorsements;

        Object.keys(this.shouldTest).forEach((key) => {
          if (this.shouldTest[key]) {
            this.assertions[key] = item.definition.output[key];
          }
        });
      }
    },
    resetForm() {
      this.$emit('update');
      this.selected = {};
      this.selectedEndorsements = [];
      this.testName = '';
      this.testId = null;
      this.copyTestId = null;
      this.resetAssertions();
    },
    async createUpdateTest() {
      this.setSelectedEndorsements();
      const testName = this.testName.trim();
      if (!testName) {
        Message.error(this.$t('tests.test_name_mandatory'));
        return false;
      }
      const testDefinition = {
        input: this.selected,
        output: {},
        endorsements: this.selectedEndorsements
      };

      Object.keys(this.assertions).forEach((key) => {
        if (this.shouldTest[key]) {
          testDefinition.output[key] = this.assertions[key];
        }
      });

      if (!Object.values(testDefinition.output).some((e) => e !== undefined)) {
        Message.error(this.$t('tests.test_assertion_mandatory'));
        return false;
      }

      let test;
      if (!this.isNewTest) {
        test = await api.updateTest(
          this.testId,
          testName,
          testDefinition,
          this.product.id
        );
        if (test.status ? [200, 201].includes(test.status) : false) {
          Message.success(this.$t('tests.update_test_success'));
        }
      } else {
        test = await api.createTest(this.product.id, testName, testDefinition);
        if (test.status ? [200, 201].includes(test.status) : false) {
          Message.success(this.$t('tests.create_test_success'));
          this.testId = test.data.id;
          this.copyTestId = null;
          this.isNewTest = false;
          delete this.$route.query.copy;
          this.$router.replace({
            name: 'product-tests-editor',
            params: { testId: this.testId },
            query: this.$route.query
          });
          this.checkCoverage();
        }
      }
      this.fetch();
      return test.data.id;
    },
    handleGeneralAction(command) {
      this[command]();
    },
    async fillWithDefaults() {
      const inputs = this.getInputToAsk;
      if (Object.keys(this.selected).length === 0) this.selected = {};
      inputs.forEach((input) => {
        if (input.metadata.defaultValue) {
          this.selected[input.technicalName] = input.metadata.defaultValue;
        }
      });
      this.dimensions.forEach((d) => {
        const prodDimension = this.product.specification.dimensions[d.type];
        if (prodDimension.metadata.defaultValue) {
          this.selected[d.type] = prodDimension.metadata.defaultValue;
        }
      });
      this.setFormatedQuestions();
      this.reRenderForms(['assertions', 'endorsements']);
      await this.checkCoverage();
    },
    async resetValues() {
      const inputs = this.getInputToAsk;
      inputs.forEach((input) => {
        this.selected[input.technicalName] = ' ';
      });
      this.dimensions.forEach((d) => {
        this.selected[d.type] = ' ';
      });
      this.selectedEndorsements = [];
      this.resetAssertions();
      await this.getQuestionsByCoverage();
      this.reRenderForms();
      await this.checkCoverage();
    },
    resetAssertions() {
      this.assertions = _.clone(this.assertions);
      Object.keys(this.shouldTest)
        .filter((assertion) => {
          return this.shouldTest[assertion];
        })
        .forEach((assertion) => {
          this.assertions[assertion] = undefined;
        });
    },
    toggleJSONView() {
      this.viewAsJSON = !this.viewAsJSON;
    },
    toggleEditingName() {
      this.editingName = !this.editingName;
      if (this.editingName) {
        this.$nextTick(() => {
          this.$refs.nameInput.focus();
        });
      }
    },
    getDisplayName(variable) {
      return displayName(variable);
    },
    answerQuestion(data) {
      this.selected[data.question.id] = data.answer;
      this.checkCoverage();
    },
    clearQuestion(data) {
      this.selected[data.question.id] = undefined;
      this.checkCoverage();
    },
    setAssertionExpectedVal(data) {
      this.assertions[data.question.id] = data.answer === 'true';
      this.checkCoverage();
    },
    unsetAssertion(data) {
      this.assertions[data.question.id] = undefined;
      this.checkCoverage();
    },
    setSelectedEndorsements() {
      if (this.endorsements.length) {
        this.selectedEndorsements =
          this.$refs.endorsements.formData.lines.root.endorsements;
      }
    },
    reRenderForms(ignoreForms = []) {
      Object.entries(this.formsKey).forEach((e) => {
        this.formsKey[e[0]] = ignoreForms.includes(e[0]) ? e[1] : e[1] + 1;
      });
    },
    async toggleRule() {
      if (this.isGraphLoading) return;
      if (this.isGraphVisible) {
        await this.hideRule();
      } else {
        await this.showRule();
      }
    },
    async showRule() {
      if (this.isGraphLoading) return;
      this.isGraphVisible = true;
      this.isGraphLoading = true;

      await this.renderRule(true);

      this.isGraphLoading = false;
    },
    async hideRule() {
      this.isGraphVisible = false;
      this.isGraphLoading = false;
    },
    async renderRule(force = false) {
      if (force === false && !this.showGraph) return;
      const { data } = await api.executeAPIQuery(
        'match',
        this.product.id,
        this.createQuery()
      );

      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }

      if (data.graph) {
        this.editor = new RuleEditor({
          container: this.$refs.editor,
          data: data.graph,
          product: this.product,
          readOnly: true,
          lang: window.localStorage.locale || 'en',
          translate: this.$t.bind(this)
        });
        this.graphIsEmpty = false;
        this.editor.render();
      } else {
        this.graphIsEmpty = true;
      }
    },
    getLabel(dim, label) {
      if (label === '_OTHER') return this.$t('rule-editor.other');
      const result = (this.product.terms[dim] || []).reduce((val, t) => {
        const def = (t.definitions || []).find((d) => d.primaryKey === label);
        return def ? def.displayName || def.primaryKey : val;
      }, '');
      return result || label;
    },
    checkQueryAnswers() {
      const { query: apiQuery } = this.$route;
      const { endorsements } = apiQuery;
      delete apiQuery.endorsements;
      (Object.keys(apiQuery) || []).forEach((k) => {
        this.selected[k] = apiQuery[k];
      });
      this.selectedEndorsements = endorsements;
    },
    endorsementsChange() {
      this.$nextTick(async () => {
        const { endorsements } = this.$refs.endorsements.formData.lines.root;
        if (!_.isEqual(endorsements, this.selectedEndorsements)) {
          this.selectedEndorsements = endorsements;
          await this.getQuestionsByCoverage();
          this.reRenderForms(['assertions', 'endorsements']);
          this.checkCoverage();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$redStatus: #e7473a;
$greenStatus: #17da99;
$lightGrey: #fefefe;
$lightPurple: #cacaf6;

#testContainer {
  display: grid;
  min-height: 100vh;
  width: 100%;
  grid-template-columns: 60% 40%;
  transform: translate3d(0, 0, 0);

  &.isReadOnly {
    grid-template-columns: 100%;
  }

  #settings {
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 2;

    .back-button {
      top: auto;
      left: auto;
    }

    .UiToolbar {
      margin-top: 0;
    }

    .actionsDropdown {
      .el-button {
        border-radius: 5px;
        background-color: $lightGrey;
        color: #464dc5;
        border-color: $lightPurple;
        font-weight: 400;
        padding: 10px 15px;
        text-transform: none;
      }
    }

    ::v-deep .testActionButton {
      .el-button {
        font-weight: 400;
        padding: 11px 15px;
        text-transform: none;
        border: 1px solid #3139be;

        i {
          border-color: $lightGrey;
        }
      }

      &.createButton {
        .el-button {
          background-color: #3139be;
          color: $lightGrey;
        }
      }

      &.updateButton {
        .el-button {
          background-color: $lightPurple;
          color: #464dc5;
        }
      }
    }

    .statusAndName {
      font-weight: 400;
      margin-top: 1rem;
      font-size: 1.4em;
      line-height: 1.4em;

      .status {
        vertical-align: middle;

        &:before {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          vertical-align: middle;
          display: inline-block;
          margin: 0 5px 3px 0;
          content: ' ';
        }

        &.success::before {
          background-color: $greenStatus;
        }

        &.failed::before {
          background-color: $redStatus;
        }
      }

      .icon {
        width: 0.9em;
        height: 0.9em;
        color: darken($lightPurple, 10%);
        vertical-align: unset;
        margin-left: 1em;
        cursor: pointer;
      }

      input {
        background-color: transparent;
        border: none;
        width: 90%;
        padding: 0;

        &:focus {
          outline: none;
        }
      }
    }

    .assertions,
    .endorsements,
    .inputs {
      h3.sectionTitle {
        font-size: 1.1rem;
        font-weight: 600;
        padding: 30px 48px 20px;
      }
    }

    .assertions {
      border-bottom: 1px solid $lightPurple;
      border-top: 1px solid $lightPurple;
    }

    #assertionsForm,
    #endorsementsForm,
    #questionsForm {
      padding: 0 25px 40px;

      ::v-deep .el-form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px 40px;

        > div {
          min-width: 0;
        }

        .el-form-item {
          margin-bottom: 0;

          .el-form-item__label {
            float: none;
            margin-bottom: 0;
            line-height: 25px;
            width: 100%;

            //This should be edited when the required property is fixed on ClaimHandler
            &::before {
              display: none;
            }

            div {
              text-align: left;
              white-space: nowrap;
              max-width: 95%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .boolean-question {
            label {
              margin-top: 0 !important;

              &:first-of-type {
                margin-left: 0 !important;
              }

              > span {
                padding: 11px 0;
              }
            }
          }

          .date-picker-widget {
            width: 100% !important;
          }

          .claim-handler-select {
            width: 100%;

            .el-select {
              display: block;
            }
          }
        }
      }
    }

    #assertionsForm {
      ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px 35px;

        .el-form-item__error {
          display: none;
        }
      }
    }

    #endorsementsForm {
      ::v-deep .el-form {
        grid-template-columns: 1fr;
        grid-gap: 15px;

        .el-form-item__label {
          display: none;
        }
      }
    }
  }

  #results {
    background-color: #fff;

    .graph {
      position: relative;
      background-color: #f5f5f8;
      height: auto;

      ::v-deep .editBar.right.retract {
        position: absolute;
      }
    }

    .graph-visible {
      height: 60vh;
    }

    .graph-toggle {
      border-bottom: 1px solid $lightPurple;
      font-weight: 600;
      background-color: #f5f5f8;
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .graph-toggle-label {
      margin-left: 0.5em;
    }

    .graph {
      height: inherit;
    }

    .graph-error {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;

      > span {
        font-size: 1.3em;

        color: darken($lightPurple, 5%);
        text-shadow: #e0e0e0 1px 1px 0;
      }
    }

    .graph-wrapper {
      position: relative;
      height: inherit;
    }
    .graph-wrapper::v-deep {
      .editBar.right.retract {
        right: 10px;
        bottom: 10px;
        position: absolute;
      }
    }

    #outputs {
      position: sticky;
      top: 50px;
      .status {
        padding: 5px 25px;
        color: #fff;
        font-weight: 400;

        &:before {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          vertical-align: middle;
          display: inline-block;
          margin: 0 5px 3px 0;
          background-color: #fff;
          content: ' ';
        }

        &.failed {
          background-color: $redStatus;
        }

        &.success {
          background-color: $greenStatus;
        }
      }

      .assertions {
        .subHeader {
          padding: 40px 25px 5px;
          border-bottom: 1px solid $lightPurple;
          display: grid;
          grid-template-columns: 1fr 1fr;

          .actions {
            text-align: right;
            text-decoration: underline;

            i {
              margin-right: 5px;
              vertical-align: middle;
            }

            span:hover {
              cursor: pointer;
            }
          }
        }

        ::v-deep .UiTable {
          .el-table {
            .ui-table-header-cell {
              color: lightgray;
              font-style: italic;
              font-size: 14px;
              border: none;
            }

            .ui-table-cell {
              border: none;

              .cell {
                min-height: 20px;
              }
            }
          }

          .singleTestResult {
            font-size: 20px;

            &.el-icon-circle-check {
              color: $lightPurple;
            }

            &.el-icon-circle-close {
              color: $redStatus;
            }
          }
        }

        .response {
          width: 95%;
          font-family: monospace;
          padding: 5px;
          border-radius: 8px;
          max-height: 500px;
          min-height: 100px;
          overflow: auto;
          display: block;
          margin: 10px auto;

          &.response--dark {
            background: #444;
            color: #fff;
          }
          &.response--large {
            min-height: 300px;
          }
        }
      }
    }
  }

  &.testContainerCompact {
    grid-template-columns: 50% 50%;
  }
}
</style>
