<template>
  <footer v-selector class="py-4 px-5">
    AXA Authoring Tool <br />
    Version {{ env.VUE_APP_VERSION }} ({{ env.VUE_APP_HASH }}) –
    {{ prettyDate(env.VUE_APP_BUILDDATE) }}
  </footer>
</template>

<script>
import * as dayjs from 'dayjs';

export default {
  name: 'VersionFooter',
  data: () => ({ env: process.env }),
  methods: {
    prettyDate(dateString) {
      return dayjs(dateString)
        .locale(window.localStorage.locale || 'en')
        .format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped>
footer {
  margin-top: 100px;
  width: 100%;
  padding: 50px 100px;
  color: #fff;
  background-color: #6e6e78;
  font-size: 16px;
  line-height: 20px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #f7f5c6;
}

.help {
  display: block;
  float: right;
}
</style>
