<template>
  <div>
    <product-export-manager
      v-if="product"
      :product-id="product.version.latest"
      :visible="showProductExportManager"
      :current-export-properties="exportProperties"
      @close="(success) => closeExportWizard()"
    ></product-export-manager>
    <header v-selector class="d-flex px-5 align-items-center not-selectable">
      <HeaderLogo :home-url="homeUrl" />
      <div v-if="isLoggedIn" class="d-flex align-items-center">
        <span v-if="showProductName" class="ProductRootline">
          <span class="ProductRootline__Breadcrumb">
            <span v-if="'TEMPLATE' === product.type" class="cartridge"
              >TEMPLATE</span
            >
            <span v-if="product.entityName" class="EntityName">
              {{ product.entityName }}
            </span>
            <span class="ProductName">
              <span class="ProductName__Name">
                {{ product.productName }}
              </span>
              <span
                v-if="product.currentVersion !== 'initial'"
                class="ProductName__Version"
              >
                {{ product.currentVersion }}</span
              >
              <ReadOnlyLabel
                class="ProductName__Status"
                :readonly="isReadOnlyMode"
                :locked="product.locked"
              />
            </span>
          </span>
          <el-button
            v-if="product"
            class="rel_pos ui_button_download"
            title="Download"
            size="mini"
            type="text"
            icon="ml-2 fas fa-download"
            @click="exportProduct"
          />
        </span>
      </div>

      <div class="ml-auto">
        <transition enter-active-class="animated fadeIn" mode="out-in" appear>
          <el-popover
            v-if="isLoggedIn && announcements.length"
            placement="bottom"
            title="Notifications"
            width="200"
            trigger="hover"
          >
            <el-badge
              slot="reference"
              :value="announcements.length"
              class="item mr-4"
            >
              <el-button circle size="mini">
                <BellIcon class="icon" />
              </el-button>
            </el-badge>
            <div>
              Latest Announcements
              <ul>
                <li v-for="announcement in announcements" :key="announcement">
                  {{ announcement }}
                </li>
              </ul>
            </div>
          </el-popover>
        </transition>
      </div>
      <div v-if="isLoggedIn" class="d-flex flex-row">
        <div class="align-self-center">
          <span class="text-right d-flex flex-row">
            <div
              v-if="
                (teams && teams.length) || (user.teams && user.teams.length)
              "
              class="select_tenant d-flex flex-row align-items-center"
            >
              <WorkspacesIcon class="icon" />
              <b-form-select
                v-model="selectedTenant"
                class="select-tenant py-0"
              >
                <b-form-select-option
                  v-for="team in getTenantList()"
                  :key="team.slug"
                  :value="team.slug"
                >
                  {{ tenantAndRole(team.id) }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div class="px-2 top_separator">|</div>
            <div class="d-flex flex-row align-items-center">
              <SocialIcon class="icon" />
              <LanguageSwitcher class="rel_pos ui_select_lang" />
            </div>
            <div class="px-2 top_separator">|</div>
          </span>
        </div>
        <div
          class="d-flex flex-row align-items-center justify-content-between gap-1"
        >
          <ManageAccountsIcon class="icon" />
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link rel_pos ui_link_account">
              {{ `${user.email}` }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link :to="{ name: 'roles' }" class="text-muted">
                <el-dropdown-item v-if="isOwner()">{{
                  $t('team.menu')
                }}</el-dropdown-item>
              </router-link>
              <router-link :to="{ name: 'profile' }" class="text-muted">
                <el-dropdown-item>{{ $t('auth.my_account') }}</el-dropdown-item>
              </router-link>
              <router-link :to="{ name: 'maintenance' }" class="text-muted">
                <el-dropdown-item v-if="isSuperAdmin()">{{
                  $t('auth.maintenance')
                }}</el-dropdown-item>
              </router-link>
              <el-dropdown-item command="logout">
                {{ $t('user.logout') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <ImpersonateDropdown
            v-if="(isSuperAdmin() || isImpersonated) && !isProd"
          />
        </div>
      </div>
    </header>
    <div
      v-if="settings && settings.displayMessage"
      class="maintenance"
      :class="{
        modeON: settings.maintenance,
        modeOFF: !settings.maintenance
      }"
    >
      {{ settings && settings.message }}
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters, mapState, mapActions } from 'vuex';
import { Message } from 'element-ui';
import BellIcon from '../assets/images/alarm-bell.svg';
import ManageAccountsIcon from '../assets/images/manage_accounts.svg';
import SocialIcon from '../assets/images/public.svg';
import WorkspacesIcon from '../assets/images/workspaces.svg';
import LanguageSwitcher from './LanguageSwitcher.vue';
import HeaderLogo from './HeaderLogo.vue';
import { name as appName } from '../config';
import ProductExportManager from './ProductExportManager.vue';
import ReadOnlyLabel from './Product/ReadOnlyLabel';
import GtmMixin from './GtmMixin';
import ImpersonateDropdown from './ImpersonateDropdown.vue';

export default {
  name: 'HeaderComponent',
  components: {
    HeaderLogo,
    ManageAccountsIcon,
    SocialIcon,
    WorkspacesIcon,
    BellIcon,
    LanguageSwitcher,
    ProductExportManager,
    ReadOnlyLabel,
    ImpersonateDropdown
  },
  mixins: [GtmMixin],
  data: () => ({
    appName,
    announcements: [],
    showProductExportManager: false,
    selectedTenant: null,
    isProductReadOnly: false,
    displayMaintenanceMessage: false
  }),
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn',
      'isAdmin',
      'isOwner',
      'isGuest',
      'isSuperAdmin',
      'isImpersonated'
    ]),
    ...mapGetters('product', ['isReadOnly']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', { user: 'getUser' }),
    ...mapState('teams', ['teams', 'tenants']),
    ...mapState('maintenance', ['settings']),
    ...mapState('product', ['product', 'isLoading']),
    ...mapState('users', ['roles']),
    homeUrl() {
      return this.tenant ? `/t/${this.tenant}/` : '';
    },
    isReadOnlyMode() {
      return (
        this.isReadOnly ||
        (this.isGuest(this.product && this.product.team.slug) &&
          !this.isOwner())
      );
    },
    showProductName() {
      return (
        this.product &&
        this.$route.name &&
        this.$route.name.startsWith('product-')
      );
    },
    superAdminTitle() {
      return (this.roles && this.roles.find((r) => r.id === 1).label) || [];
    },
    selectedTeam() {
      return (
        this.teams &&
        this.teams.find(
          (t) => this.selectedTenant && t.tenant === this.selectedTenant
        )
      );
    },
    exportProperties() {
      return {
        name: this.product.name,
        type: this.product.type
      };
    },
    isProd() {
      return process.env.NODE_ENV === 'production';
    }
  },
  watch: {
    user: {
      async handler() {
        if (this.user) {
          await this.getSettings();
          if (!this.roles) await this.getRoles();
          if (this.isAdmin()) {
            if (!this.tenants) await this.loadTenants();
          }
          if (!this.teams) await this.loadTeams();
        }
      },
      immediate: true
    },
    tenant: {
      async handler() {
        this.selectedTenant = this.tenant;
        if (this.tenant) this.setGtm(this.tenant);
      },
      immediate: true
    },
    selectedTenant() {
      this.setTenant({ tenant: this.selectedTenant, forceReload: true });
    }
  },
  methods: {
    ...mapActions('auth', ['logOut', 'setTenant']),
    ...mapActions('teams', ['loadTenants', 'loadTeams']),
    ...mapActions('users', ['getRoles']),
    ...mapActions('maintenance', ['getSettings']),
    handleCommand(command) {
      if (command) {
        switch (command) {
          case 'logout':
            this.logOutAndRedirect();
            break;
          default:
            break;
        }
      }
    },
    closeExportWizard() {
      this.showProductExportManager = false;
    },
    exportProduct() {
      this.showProductExportManager = true;
    },
    async logOutAndRedirect() {
      await this.logOut();
      Message.info('You have been logged out');
    },
    getTenantList() {
      const tenants = this.isSuperAdmin()
        ? this.tenants && [...this.tenants]
        : this.user.teams && [
            ...this.user.teams.filter((t) => t.slug === t.tenant)
          ];
      return !tenants
        ? tenants
        : tenants.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
    },
    tenantAndRole(tenantId) {
      if (this.isSuperAdmin()) {
        return this.tenants.find((g) => g.id === tenantId).label;
      }
      const team =
        this.user.teams && this.user.teams.find((g) => g.id === tenantId);
      const role =
        this.roles &&
        this.roles.find((r) => r.id === team.UserTeam.team_role_id);
      return `${(team && team.label) || ''} (${(role && role.label) || ''})`;
    }
  }
};
</script>

<style lang="scss">
@import '@axatechlab/assets/scss/_variables';

.rel_pos {
  position: relative;
}

header {
  position: relative;
  z-index: 2;
  min-height: 34px;
  background: #f0f0f0;
  border-bottom: 1px solid rgba(43, 48, 52, 0.1);
  text-transform: uppercase;
  font-family: $headings-font-family;
  .el-dropdown {
    font-size: $font-size-base;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-setting {
    color: $color-axa-blue;
  }

  .select-tenant {
    color: #343c3d;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: #ffffff00
      url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e')
      right 20px center/8px 10px no-repeat;
    text-transform: uppercase;
    max-width: 320px;
    text-overflow: ellipsis;
    max-height: 32px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  button .icon {
    height: 14px;
    width: 14px;
  }

  .app-name {
    color: $color-axa-blue;
  }

  .is-circle {
    width: 38px;
    height: 38px;
  }

  .logo {
    width: 50px;
    height: 50px;
    // Align logo to top
    display: inline-block;
    position: relative;
    top: -4px;
  }

  .helpIcon {
    font-size: 2em;
    margin-left: 0.8em;
  }

  .cartridge {
    margin-left: 1em;
    margin-right: 1em;
    padding: 0px 10px;
    background-color: gray;
    color: white;
    border-radius: 1em;
  }
  .top_separator {
    font-size: 0.8em;
    display: inline-block;
    padding-top: 0.2em;
    color: #343c3d;
  }
}

.ProductName {
  display: flex;
  align-items: center;

  > span {
    margin-right: 0.2em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ProductVersion {
  margin-left: 0.4rem;
}
.ProductRootline {
  display: flex;
  align-items: center;

  &__Breadcrumb {
    display: flex;
    align-items: center;
    > span {
      &:after {
        content: '-';
        margin: 0 0.2rem;
      }
      &:last-child {
        &:after {
          content: '';
          margin-right: 0;
        }
      }
    }
  }
}
.maintenance {
  width: 100%;
  padding: 8px;
  text-align: center;
}
.modeON {
  background: red;
  color: white;
}
.modeOFF {
  background: #53dcea;
  color: black;
}

.temporarilyHidden {
  width: 60px;

  a {
    display: none !important;
  }
}

.gap-1 {
  gap: 1em;
}

::v-deep .el-select .el-input--mini {
  font-weight: 600;
  font-size: 14px;
}
</style>
