var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('div',{class:_vm.containerClass},[(_vm.rule && (_vm.showGraph || !_vm.isTable))?[(
        !_vm.isTestCoverageMode &&
        _vm.getFormattedWarnings()[_vm.rule.name] &&
        _vm.getFormattedWarnings()[_vm.rule.name].length > 0
      )?_c('div',{staticClass:"warnings-table",class:{ collapsed: _vm.hideWarnings }},[_c('button',{staticClass:"warning-header",on:{"click":function($event){_vm.hideWarnings = !_vm.hideWarnings}}},[(_vm.hideWarnings)?_c('div',{staticClass:"el-icon-warning"}):_vm._e(),_vm._v("\n        "+_vm._s(_vm.warnings[_vm.rule.name].length)+"\n        Warning"+_vm._s(_vm.warnings[_vm.rule.name].length > 1 ? 's' : '')+"\n        "),_c('div',{class:{
            'el-icon-minus': !_vm.hideWarnings,
            'el-icon-plus': _vm.hideWarnings
          }})]),_vm._v(" "),_c('div',{staticClass:"warnings"},_vm._l((_vm.warnings[_vm.rule.name]),function(warning,index){return _c('button',{key:index,staticClass:"warning",on:{"click":function($event){return _vm.nodeFocus({ node: warning.id, rule: _vm.ruleId })}}},[_c('div',{staticClass:"el-icon-warning"}),_vm._v("\n          "+_vm._s(_vm.$t(`ide.warnings.${warning.code}`, {
              ...warning.data,
              code: warning.data.code
                ? _vm.$t(`ide.warnings.${warning.data.code}`)
                : undefined,
              inputs: warning.data.inputs
                ? warning.data.inputs.join(',')
                : undefined
            }))+"\n        ")])}),0)]):_vm._e(),_vm._v(" "),_c('UiLoadingArea',{attrs:{"is-loading":_vm.isLoading}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],key:_vm.ruleId,ref:"editor",attrs:{"id":"ruleEditor"}})]:_vm._e(),_vm._v(" "),(_vm.rule && _vm.isTable && _vm.showTable)?_c('TableRule',{attrs:{"rule":_vm.rule}}):_vm._e(),_vm._v(" "),(!_vm.rule)?_c('div',{staticClass:"px-4"},[_c('h3',{staticClass:"py-3"},[_vm._v("Not Found")]),_vm._v(" "),_c('p',[_vm._v("This rule does not exist.")])]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }