<template>
  <div>
    <UiBaseLayout v-if="isLoggedIn" v-selector.view>
      <div
        class="d-flex flex-row justify-content-between bg-white px-4 align-items-center"
      >
        <nav class="nav-bar">
          <a
            class="px-3"
            :class="{ 'router-link-active': activeTab === 'tenants-tab' }"
            @click="activeTab = 'tenants-tab'"
            ><span>Tenants</span></a
          >
          <a
            class="px-3"
            :class="{ 'router-link-active': activeTab === 'users-tab' }"
            @click="activeTab = 'users-tab'"
            ><span>Users</span></a
          >
        </nav>
      </div>
      <TenantsIndex v-show="activeTab === 'tenants-tab'" />
      <UsersIndex
        v-show="activeTab === 'users-tab'"
        :on-click-invite="changeTabToInvitations"
      ></UsersIndex>
    </UiBaseLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TenantsIndex from './TenantsIndex';
import UsersIndex from './UsersIndex';

export default {
  name: 'RolesAndRights',
  components: {
    TenantsIndex,
    UsersIndex
  },
  data: () => ({
    activeTab: 'tenants-tab'
  }),
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isOwner', 'isLoggedIn'])
  },
  watch: {
    isOwner: {
      immediate: true,
      handler(isOwner) {
        if (!isOwner()) {
          this.$router.replace('products');
        }
      }
    }
  },
  methods: {
    changeTabToInvitations() {
      this.activeTab = 'invitations-tab';
    }
  }
};
</script>

<style scoped>
nav a {
  text-transform: uppercase;
  letter-spacing: 1px;
  user-select: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

a.router-link-active {
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: #d24723;
  }
  color: $color-axa;
  opacity: 1;
  font-weight: 600;
}

.homeNav {
  background-color: #fff;
}

.nav-bar {
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-end;
}
</style>
