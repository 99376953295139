<template>
  <div class="flex-1 d-flex flex-column">
    <div v-selector.view class="full-height container py-5">
      <h3>{{ $t('maintenance.title') }}</h3>
      <div>
        <p class="py-3 rel_pos ui_label_username">
          {{ $t('maintenance.message_header') }}
        </p>
        <el-input
          v-model="message"
          type="textarea"
          :rows="2"
          placeholder="Please input"
        >
        </el-input>

        <UiPaddedArea>
          <UiToolbar>
            <el-switch
              v-model="maintenance"
              :active-text="$t('maintenance.mode_enabled')"
              class="warningsSwitch"
            >
            </el-switch>
            <el-switch
              v-model="displayMessage"
              :active-text="$t('maintenance.display_message')"
              class="warningsSwitch"
            >
            </el-switch>
          </UiToolbar>
        </UiPaddedArea>

        <button
          :disabled="!canSave"
          type="text"
          class="btn btn-ghost ui_button_logout"
          @click="saveMessage()"
        >
          Save maintenance configuration
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Maintenance',
  components: {},
  mixins: [],
  data() {
    return {
      message: 'default message',
      maintenance: true,
      displayMessage: true
    };
  },
  computed: {
    ...mapState('maintenance', ['settings']),
    canSave() {
      return this.message !== '';
    }
  },
  watch: {
    settings: {
      async handler() {
        if (this.settings && this.settings.message) {
          this.fetchMaintenanceSettings();
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.fetchMaintenanceSettings();
  },
  methods: {
    ...mapActions('maintenance', ['saveSettings']),
    fetchMaintenanceSettings() {
      this.message = this.settings.message || this.message;
      this.displayMessage = this.settings.displayMessage;
      this.maintenance = this.settings.maintenance;
    },
    async saveMessage() {
      try {
        await this.saveSettings({
          maintenance: this.maintenance,
          displayMessage: this.displayMessage,
          message: this.message
        });

        if ((this.$store.state.maintenance.errors || []).length > 0) {
          const error = this.$store.state.maintenance.errors[0];
          const message =
            error[0].message ||
            `An error occurred. Please check that you are superadmin to perform this operation.`;
          Message.error(message);
        } else {
          Message.success(this.$t('maintenance.msg-success-save'));
        }
      } catch (error) {
        Message.error(error.message || `An unexpected error occurred.`);
      }
    }
  }
};
</script>

<style scoped></style>
