<template>
  <div>
    <div ref="shell" class="AppShell">
      <div class="AppShell__Wrapper">
        <Shell ref="cli" @close="closeShell()" />
      </div>
    </div>
    <div id="app" :class="[routeName]">
      <div class="AppWrapper">
        <AppHeader />
        <transition mode="out-in" appear enter-active-class="animated fadeIn">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Mousetrap from 'mousetrap';
import AppHeader from './components/HeaderComponent.vue';
import Shell from './components/Shell.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    Shell
  },
  data() {
    return {
      ruleId: undefined,
      testId: undefined,
      currentApp: undefined
    };
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin']),
    ...mapGetters(['fullState']),
    ...mapState('route', { routeName: 'name' }),
    ...mapState('product', ['product']),
    ticketData() {
      const data = {
        project_id: 12,
        files: [undefined, this.fullState],
        description: `
        App version: ${process.env.VUE_APP_VERSION}
        Git branch: ${process.env.VUE_APP_BRANCH}
        Build date: ${process.env.VUE_APP_BUILDDATE}`,
        external_links: [window.location.href]
      };
      return data;
    }
  },
  watch: {
    'product.id': {
      handler() {
        this.ruleId = undefined;
        this.testId = undefined;
        this.currentApp = undefined;
        if (this.product) this.ruleId = this.product.mainRuleId;
      }
    }
  },
  mounted() {
    Mousetrap.bind(['mod+shift+alt+s'], () => this.toggleShell());
    Mousetrap.bind(['mod+shift+alt+i'], () => {
      if (this.showUITags) {
        this.showUITags = false;
        document.querySelectorAll('._u_i_tag').forEach((node) => {
          node.remove();
        });
      } else {
        this.showUITags = true;
        document.querySelectorAll('[class*="ui_"]').forEach((element) => {
          const tag = element.className
            .split(' ')
            .filter((name) => {
              return name.startsWith('ui_');
            })
            .last();
          element.insertAdjacentHTML(
            'beforeend',
            `<div class='_u_i_tag'>${tag}</div>`
          );
        });
      }
    });

    this.$router.beforeEach((to, from, next) => {
      if (to) {
        if (to.name === 'product-tests') {
          if (from.name !== 'product-tests-editor') {
            if (this.testId) {
              this.$router.replace({
                name: 'product-tests-editor',
                params: { ...to.params, testId: this.testId }
              });
            }
          } else if (to.name === 'product-tests-editor') {
            this.testId = undefined;
          }
        } else if (to.name === 'product-tests-editor') {
          this.testId = to.params.testId;
        }

        if (to.name === 'product-apps') {
          if (
            from.matched[from.matched.length - 1].parent.name !==
            'product-apps-container'
          ) {
            if (this.currentApp) {
              this.$router
                .replace({
                  name: this.currentApp,
                  params: { ...to.params }
                })
                .catch(() => {});
            }
          } else {
            this.currentApp = undefined;
          }
        } else if (
          to.matched[to.matched.length - 1] &&
          to.matched[to.matched.length - 1].parent &&
          to.matched[to.matched.length - 1].parent.name ===
            'product-apps-container'
        ) {
          this.currentApp = to.name;
        }
      }
      next();
    });

    window.addEventListener('message', this.messageListener);
  },
  destroyed() {
    window.removeEventListener('message', this.messageListener);
  },
  methods: {
    messageListener(e) {
      if (typeof e.data === 'string') {
        const [namespace, eventName, data] = e.data.split('::');

        if (namespace === 'cc') {
          switch (eventName) {
            case 'debug-info':
              window.postMessage(
                `debug::info::${JSON.stringify(this.ticketData)}`
              );
              break;
            default:
              // eslint-disable-next-line no-console
              console.warn(
                'CC postMessage event not handled',
                eventName,
                JSON.parse(data)
              );
          }
        }
      }
    },
    closeShell() {
      this.$refs.shell.classList.remove('AppShell--Visible');
    },
    toggleShell() {
      if (!this.isSuperAdmin) return;
      this.$refs.shell.classList.toggle('AppShell--Visible');
      if (this.$refs.shell.classList.contains('AppShell--Visible')) {
        this.$refs.cli.focus();
      }
    }
  }
};
</script>

<style lang="scss">
.AppShell {
  display: none;
  position: fixed;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: 100vh;
  z-index: 10000;

  &--Visible {
    display: block;
  }

  &__Wrapper {
    top: 0;
  }
}

.AppWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#app {
  position: relative;
  min-width: 640px;
  min-height: 100vh;
}

.no-header .el-dialog__header {
  display: none;
}

._u_i_tag {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 2px 5px;
  background-color: white;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: 8px;
  font-style: initial;
  text-transform: none;
  line-height: 1em;
  border: 1px solid orange;
  opacity: 0.7;
}
</style>
