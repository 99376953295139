<template>
  <UiTable
    ref="table"
    :data="properties"
    :class="`ui_table_${property}`"
    row-key="name"
    :expand-row-keys="expandRowKeys"
    variant="padded"
    :default-sort="sort"
    :row-class-name="rowClassName"
    @dblclick="(row) => $emit('dblclick', row)"
    @sort-change="handleSortChange()"
  >
    <template v-if="showExpanded" #expand="{ row, $index }">
      <slot name="expand" :row="row" :index="$index"></slot>
    </template>
    <template #default="{ row, $index }">
      <slot :row="row" :index="$index" />
    </template>
  </UiTable>
</template>

<script>
export default {
  name: 'ProductConfigurationTable',
  props: {
    property: {
      type: String,
      required: true
    },
    sortField: {
      type: String,
      required: true
    },
    sortOrder: {
      type: String,
      required: true
    },
    properties: {
      type: Array,
      required: true
    },
    rowClassName: {
      type: Function,
      default: null
    },
    expandRowKeys: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      sort: { prop: 'name', order: 'ascending' }
    };
  },
  computed: {
    showExpanded() {
      return !!this.$scopedSlots.expand;
    }
  },
  watch: {
    sortField(value) {
      this.sort.prop = value;
    },
    sortOrder(value) {
      this.sort.order = value;
    }
  },
  mounted() {
    this.sort = { prop: this.sortField, order: this.sortOrder };
  },
  methods: {
    handleSortChange() {
      const { sort } = this;
      this.$emit(
        'sort-change',
        sort.prop,
        sort.order === 'ascending' ? 'descending' : 'ascending'
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
