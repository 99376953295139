/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { types } from './product.types';

export default {
  [types.SET_COMPLEXITY_ALLOWED](state, val) {
    state.complexityAllowed = val;
  },
  [types.FETCH_PRODUCT_REQUEST](state, resetProduct = false) {
    if (resetProduct) {
      state.isLoading = true;
      state.product = null;
      state.complexity = null;
      state.complexityAllowed = false;
    }
    state.error = null;
  },
  [types.FETCH_PRODUCT_SUCCESS](state, { product }) {
    if (state.product?.id !== product.id) {
      state.copilotStatus = undefined;
    }
    state.product = product;
    state.isLoading = false;
  },
  [types.FETCH_PRODUCT_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error || null;
  },
  [types.SET_SIDEBAR_VISIBILITY](state, visibility) {
    state.sidebarIsVisible = visibility;
  },
  [types.SET_PRODUCT_CONFIG_ACTIVE_TAB](state, activeConfigTab) {
    state.activeConfigTab = activeConfigTab;
  },
  [types.SET_INSPECTOR_VISIBILITY](state, visibility) {
    state.inspectorIsVisible = visibility;
    window.localStorage.setItem(
      'RuleEditorShowInspector',
      visibility ? 'true' : 'false'
    );
  },
  [types.SET_RULE_DISPLAY_TYPE](state, type) {
    state.ruleDisplayType = type;
    window.localStorage.setItem('RuleEditorDisplayType', type);
  },
  [types.SET_TEST_COVERAGE_MODE](state, activation) {
    state.testCoverageMode = activation;
  },
  [types.SET_RULE_ID](state, ruleId) {
    state.ruleId = ruleId;
  },
  [types.FETCH_PRODUCT_WARNINGS_REQUEST]() {},
  [types.FETCH_PRODUCT_WARNINGS_SUCCESS](state, { graph, warnings }) {
    state.graphWithId = graph;
    state.warnings = warnings;
  },
  [types.SET_REQUEST_WARNINGS](state, requestWarnings) {
    state.requestWarnings = requestWarnings;
  },
  [types.FETCH_PRODUCT_WARNINGS_ERROR](state) {
    state.graphWithId = null;
    state.warnings = null;
  },
  [types.FETCH_PRODUCT_COMPLEXITY_REQUEST]() {},
  [types.FETCH_PRODUCT_COMPLEXITY_SUCCESS](state, complexity) {
    state.complexity = complexity;
  },
  [types.FETCH_PRODUCT_COMPLEXITY_ERROR](state) {
    state.complexity = {};
  },
  [types.SET_RULE_ID](state, ruleId) {
    state.ruleId = ruleId;
  },
  [types.SET_SHARED_PROPERTIES](state, sharedProperties) {
    state.sharedProperties = sharedProperties;
  },
  [types.SET_TECHNICAL_NAME_VALIDATION_ERROR](state, error) {
    state.technicalNameValidationError = error;
  },
  [types.SET_TECHNICAL_NAME_VALIDATION_SUCCESS](state) {
    state.technicalNameValidationError = null;
  },
  [types.GET_RELATED_PRODUCT_REQUEST]() {},
  [types.GET_RELATED_PRODUCT_SUCCESS]() {},
  [types.GET_RELATED_PRODUCT_ERROR]() {},
  [types.TOGGLE_LOCK](state, { lock }) {
    state.product = {
      ...state.product,
      locked: lock,
      readonly: lock
    };
  },
  [types.UPLOAD_PDF_REQUEST]() {},
  [types.UPLOAD_PDF_SUCCESS]() {},
  [types.UPLOAD_PDF_ERROR]() {},
  [types.SET_COPILOT_STATUS](state, value) {
    state.copilotStatus = value;
  },
  [types.SET_EXTRACTED_DIMENSIONS](state, value) {
    state.extractedDimensions = value;
  },
  [types.SET_PRODUCT_EXTRACTING_DIMENSIONS](state, value) {
    state.extractingDimensions = value;
  }
};
