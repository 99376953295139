<template>
  <div class="ProductInputsSummary product-summary">
    <div class="title d-flex ui_label_title">
      <div class="label">{{ $t('product.input_variables') }}</div>
    </div>
    <div class="mt-4 d-flex flex-row flex-wrap">
      <div v-for="input in inputs" :key="input.id">
        <ProductLink
          class="d-flex flex-1 ui_link_input"
          name="configuration"
          tag="div"
          :product="product"
          @click.native="setInputActiveTab"
        >
          <a class="input mr-2 mb-2">{{ getInputName(input) }}</a>
        </ProductLink>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center mt-3">
      <ProductLink
        class="config ui_link_configuration"
        name="configuration"
        :product="product"
        @click.native="setInputActiveTab"
      >
        {{ $t('product.configuration') }}
      </ProductLink>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import ProductLink from './Product/ProductLink';

export default {
  name: 'ProductInputsSummary',
  components: { ProductLink },
  props: { product: { type: Object, required: true } },
  computed: {
    inputs() {
      const array = _.map(Object.keys(this.product.specification.in), (key) => {
        return { ...this.product.specification.in[key], id: key };
      });
      return _.slice(array, 0, 10);
    }
  },
  methods: {
    ...mapActions('product', ['setProductConfigActiveTab']),
    getInputName(input) {
      const { displayName } = input.metadata || {};
      return (typeof displayName === 'string' && displayName) || input.id;
    },
    setInputActiveTab() {
      this.setProductConfigActiveTab({ activeTab: 'input' });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.title {
  position: relative;
  font-size: 150%;
  .icon {
    display: inline-block;
  }
}

.input {
  padding: 0.15em 0.7em;
  border-radius: 5px;
  background-color: #dedef1;
}

.config {
  position: relative;
  margin-left: 1em;
  padding: 0.15em 0.7em;
  border: solid 1px $color-active;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #f5f5f5;
}

.ui_link_input .input {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
