<template>
  <UiBaseLayout>
    <input ref="fileUpload" type="file" hidden @input="handleSelectedFile" />
    <UiDialog
      title="Import definition list"
      :visible="displayDefinitionListOverview"
      width="80%"
      confirm-label="Confirm import"
      :disable-confirm="true"
      :hide-confirm="true"
      @close="closeImportListOverview()"
      @cancel="cancelImportListOverview()"
    >
      <template #dialog-content>
        <definition-lists-overview
          v-if="displayDefinitionListOverview"
          :definitions-lists="definitionListForImport"
          :definition-list-suggestions="definitionListSuggestions"
          @callback="(response) => onListImported(response)"
        />
      </template>
    </UiDialog>

    <template #header> </template>
    <template #footer>
      <VersionFooter class="mt-auto" />
    </template>
    <div class="center-table">
      <UiPaddedArea class="p-0">
        <UiToolbar>
          <div class="flex-spacer" />
          <b-input-group class="search-group">
            <b-form-input
              v-model="searchStr"
              class="search-input"
              type="search"
              :placeholder="$t('product.search_definitions')"
            />
            <b-icon icon="search" class="search-input-icon"></b-icon>
          </b-input-group>
          <div class="flex-spacer" />
          <b-button
            v-if="!isGuest(tenant)"
            variant="primary"
            @click="createNewDefinitionList"
          >
            <i class="fas fa-plus mr-2"></i>{{ $t('definition-lists.create') }}
          </b-button>
          <template #side>
            <b-dropdown variant="outline-primary" right no-caret>
              <template #button-content> <b-icon-three-dots /> </template>
              <template v-if="!isReadOnlyMode && isAdmin(tenant)">
                <b-dropdown-item>
                  <b-button @click="$refs.fileUpload.click()">
                    {{
                      $t(
                        'product.import-definitions-list.import-new-definition-list'
                      )
                    }}
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-divider />
              </template>
              <b-dropdown-item @click="exportAs('xlsx')">
                <i class="fas fa-download"></i>
                {{ $t('common.download-as-excel') }}
              </b-dropdown-item>
              <b-dropdown-item @click="exportAs('csv')">
                <i class="fas fa-download"></i>
                {{ $t('common.download-as-csv') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </UiToolbar>
      </UiPaddedArea>
      <UiLoadingArea :is-loading="isLoading">
        <UiTable
          :data="definitionLists"
          :default-sort="{ prop: sortField, order: sortOrder }"
          variant="padded"
          class="ui_table_definitions"
          @sort-change="onSort"
        >
          <el-table-column
            :label="$t('definition-lists.list')"
            prop="name"
            sortable="custom"
          >
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'definition-lists-edit',
                  params: { definitionListId: scope.row._id }
                }"
                class="definition-list-name ui_link_definition"
                >{{ scope.row.name }}</router-link
              >

              <el-tooltip
                v-if="
                  definitionListValidations[scope.row._id] &&
                  definitionListValidations[scope.row._id].isValid === false
                "
              >
                <i class="el-icon-warning-outline"></i>
                <template slot="content">
                  <div
                    v-for="(error, index) of definitionListValidations[
                      scope.row._id
                    ].errors"
                    :key="index"
                  >
                    <div v-if="!error.isValid">
                      <span>{{ error.code }}</span>
                      <ul>
                        <li
                          v-for="(value, k) of error.invalidValues.slice(0, 5)"
                          :key="k"
                        >
                          {{ formatInvalidValue(value) }}
                        </li>
                        <li v-if="error.invalidValues.length > 5">
                          <i>
                            <br />
                            And
                            {{ error.invalidValues.length - 5 }}
                            more...</i
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('definition-lists.definition')"
            prop="definitionsCount"
            class-name="ui_label_def_count"
          >
            <template slot-scope="scope">
              {{
                (definitionListValidations[scope.row._id] &&
                  definitionListValidations[scope.row._id].definitions &&
                  definitionListValidations[scope.row._id].definitions.count) ||
                0
              }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('definition-lists.last-update')"
            prop="_updatedAt"
            sortable
            class-name="ui_label_update_date"
            ><template slot-scope="scope">{{
              getModifiedDate(scope.row)
            }}</template></el-table-column
          >
          <el-table-column
            fixed="right"
            :label="$t('definition-lists.operation')"
            width="320"
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="!isReadOnlyMode && isAdmin(tenant)"
                :disabled="isDeletable(scope.row)"
              >
                <span>
                  <el-button
                    :disabled="!isDeletable(scope.row)"
                    type="danger"
                    plain
                    size="mini"
                    class="ui_button_delete"
                    data-cy="definition-button-delete"
                    @click="deleteDefinitionList(scope.row)"
                  >
                    {{ $t('action.delete') }}
                  </el-button>
                </span>
                <template slot="content">
                  <div v-if="definitionListValidations[scope.row._id]">
                    Used by {{ productsUsedByDefinitionList(scope.row._id) }}
                    products as term
                  </div>
                  <div v-if="definitionListValidations[scope.row._id]">
                    Used by
                    {{ propertiesUsedByDefinitionList(scope.row._id) }}
                    properties
                  </div>
                  <div v-if="definitionListValidations[scope.row._id]">
                    Used by
                    {{ productPropertiesUsedByDefinitionList(scope.row._id) }}
                    product as properties
                  </div>
                </template>
              </el-tooltip>

              <router-link
                :to="{
                  name: 'definition-lists-edit',
                  params: { definitionListId: scope.row._id }
                }"
                class="rel_pos ui_link_to"
              >
                <el-button size="mini" plain>{{ $t('action.view') }}</el-button>
              </router-link>
            </template>
          </el-table-column>
        </UiTable>
        <UiPagination
          v-if="!!definitionLists"
          :current-page.sync="page"
          :page-size="count"
          :total="definitionCount"
        />
      </UiLoadingArea>
    </div>
  </UiBaseLayout>
</template>

<script>
import { Message, MessageBox } from 'element-ui';
import { mapGetters, mapActions, mapState } from 'vuex';
import VersionFooter from '../components/VersionFooter.vue';
import * as api from '../api';
import { noop, getHumanDateFromISO, exportToList, readFromFile } from '../util';
import DefinitionListMixin from '../components/DefinitionListMixin';
import DefinitionListsOverview from '../components/DefinitionLists/DefinitionListsOverview.vue';
import feathersClient from '../feathers';

export default {
  name: 'DefintionListsIndex',
  components: {
    VersionFooter,
    DefinitionListsOverview
  },
  mixins: [DefinitionListMixin],
  data() {
    return {
      definitionLists: [],
      definitionCount: 0,
      searchStr: '',
      page: 1,
      count: 25,
      sortField: '_updatedAt',
      sortOrder: 'descending',
      searchTimeout: null,
      isLoading: false,
      teams: [],
      contentJSON: null,
      isDisplayedDefinitionListOverview: false,
      definitionListForComparison: null
    };
  },
  computed: {
    ...mapState('definitionListSuggestions', {
      definitionListSuggestions: 'suggestions'
    }),
    ...mapState('definitions', ['definitions', 'definitionListValidations']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', ['isOwner', 'isAdmin', 'isGuest']),
    ...mapGetters('maintenance', ['isMaintenance']),
    ...mapGetters('definitions', [
      'getDefinitionProductsCount',
      'getDefinitionPropertiesCount',
      'getDefinitionProductPropertiesCount'
    ]),

    isReadOnlyMode() {
      return this.isGuest(this.tenant) || this.isMaintenance;
    },
    displayDefinitionListOverview() {
      return this.isDisplayedDefinitionListOverview;
    },
    definitionListForImport() {
      if (!this.contentJSON) {
        return [];
      }

      return [
        {
          ...this.contentJSON,
          ...{
            type: 'DEFINITION-LIST',
            filename: `DEFINITION-LIST---${
              (this.contentJSON.metadata && this.contentJSON.metadata.name) ||
              'no-name'
            }`
          }
        }
      ];
    }
  },
  watch: {
    definitionLists() {
      if (!this.definitionLists || this.definitionLists.length === 0) return;
      this.definitionLists.map((dl) =>
        this.fetchDefinitionListValidation({ definitionListId: dl._id })
      );
    },
    searchStr: {
      handler(value) {
        if (this.firstFetch) return;
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.$router.replace({
          path: this.$router.currentRoute.path,
          query: {
            page: 1,
            search: value,
            order: this.sortOrder,
            field: this.sortField
          }
        });
        // Add a timeout on search to avoid multiple useless requests
        this.searchTimeout = setTimeout(() => {
          this.page = 1;
          this.products = [];
          this.fetchDefinitionLists();
          this.searchTimeout = null;
        }, 300);
      }
    },
    contentJSON() {
      if (this.contentJSON) {
        this.isDisplayedDefinitionListOverview = true;
      }
    },
    page: {
      handler(value) {
        if (this.firstFetch) return;
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: {
            page: value,
            search: this.searchStr,
            order: this.sortOrder,
            field: this.sortField
          }
        });
        this.fetchDefinitionLists();
      }
    }
  },
  mounted() {
    const { query } = this.$route;
    this.page = parseInt(query.page || 1, 10);
    this.count = parseInt(query.count || 25, 10);
    this.sortOrder = query.order || 'descending';
    this.sortField = query.field || '_updatedAt';
    this.searchStr = '';
    this.fetchDefinitionLists();
    if (this.isOwner(this.tenant) && !this.teams.length) {
      this.fetchTeams();
    }
    if (!this.isOwner(this.tenant)) {
      this.teams = [];
    }
  },
  methods: {
    productsUsedByDefinitionList(dlId) {
      return this.getDefinitionProductsCount(dlId);
    },

    propertiesUsedByDefinitionList(dlId) {
      return this.getDefinitionPropertiesCount(dlId);
    },

    productPropertiesUsedByDefinitionList(dlId) {
      return this.getDefinitionProductPropertiesCount(dlId);
    },

    async handleSelectedFile(ev) {
      const event = ev;
      const file = (event.target.files && event.target.files[0]) || null;
      if (!file || !file.name.endsWith('.json')) {
        Message.error(this.$t('definition-lists.msg-file-error'));
        return;
      }
      try {
        const dataJSON = await readFromFile(file);
        const dlName = (dataJSON.metadata && dataJSON.metadata.name) || null;
        const remoteDefinitionsLists = await feathersClient
          .service('2.0/definition-list')
          .find({ query: { name: dlName, tenant: this.tenant } });
        this.definitionListForComparison = remoteDefinitionsLists;
        this.contentJSON = dataJSON;
        event.target.value = '';
      } catch (error) {
        Message.error(this.$t('definition-lists.msg-invalid-json-content'));
      }
    },
    closeImportListOverview() {
      this.isDisplayedDefinitionListOverview = false;
      this.contentJSON = null;
    },
    cancelImportListOverview() {
      this.closeImportListOverview();
    },
    confirmImportListOverview() {
      this.closeImportListOverview();
    },
    onListImported(response) {
      this.closeImportListOverview();
      const { id } = response;
      this.$router.push({
        name: 'definition-lists-edit',
        params: { definitionListId: id }
      });
    },
    ...mapActions('definitions', [
      'saveDefinitionListTeam',
      'fetchDefinitions',
      'fetchDefinitionListValidation'
    ]),
    isDeletable(row) {
      return (
        this.getDefinitionProductsCount(row._id) === 0 &&
        this.getDefinitionPropertiesCount(row._id) === 0 &&
        this.getDefinitionProductPropertiesCount(row._id) === 0
      );
    },
    async fetchTeams() {
      try {
        const { data } = await api.listTeams();
        if (data) {
          this.teams = data;
        }
      } catch (err) {
        noop();
      }
    },
    async updateDefinitionListTeam(definitionList, teamId) {
      try {
        const response = await this.saveDefinitionListTeam({
          // eslint-disable-next-line no-underscore-dangle
          definitionListId: definitionList._id,
          currentTeam: this.tenant,
          newTeamId: teamId
        });
        if (response) {
          Message.info(
            this.$t('definition-lists.msg-success-update', {
              type: definitionList.name
            })
          );
        }
      } catch (err) {
        const message = err.response.data.error || err.toString();
        MessageBox.alert(message, this.$t('definition-lists.update_failed'));
      }
    },
    async fetchDefinitionLists() {
      try {
        this.isLoading = true;
        const search = this.searchStr;
        if (this.cancelToken) {
          this.cancelToken.cancel('Request canceled');
        }
        this.cancelToken = api.getCancelToken();
        const data = await api.getDefinitionLists({
          search: encodeURI(this.searchStr),
          page: this.page,
          count: this.count,
          field: this.sortField,
          order: this.sortOrder,
          cancelToken: this.cancelToken.token,
          tenant: this.tenant
        });

        if (search !== this.searchStr) {
          return;
        }

        if (data) {
          this.definitionLists = data.items;
          this.definitionCount = data.count;
        }
      } catch (err) {
        return;
      } finally {
        this.cancelToken = null;
        this.isLoading = false;
      }
    },

    async createNewDefinitionList() {
      try {
        const { value } = await MessageBox.prompt(
          this.$t('definition-lists.name'),
          this.$t('definition-lists.create'),
          {
            inputPattern: /^[\w-\s()]{1,31}$/,
            inputErrorMessage: this.$t('definition-lists.input-error')
          }
        );
        if (value == null) {
          await Message.error(this.$t('definition-lists.failed-create'));
        }
        const { data } = await api.createDefinitionList(value, this.tenant);
        this.$router
          .push({
            name: 'definition-lists-edit',
            params: { definitionListId: data.id }
          })
          .catch(() => {});
        await Message.success(this.$t('definition-lists.success-create'));
      } catch (err) {
        if (err !== 'cancel') Message.error(err);
      }
    },
    async deleteDefinitionList(row) {
      try {
        const value = await MessageBox.confirm(
          this.$t('definition-lists.cannot-undone'),
          `${this.$t('action.delete')} “${row.name}”?`
        );
        if (value === 'confirm') {
          // eslint-disable-next-line no-underscore-dangle
          await api.deleteDefinitionList(row._id, this.tenant);
          this.fetchDefinitionLists();
          Message.info(this.$t('definition-lists.success-delete'));
        }
      } catch (err) {
        Message.error(err);
      }
    },
    async onSort(field, order) {
      try {
        if (order && field) {
          if (order === this.sortOrder && field === this.sortField) {
            return;
          }
          this.sortField = field;
          this.sortOrder = order;
          this.$router.push({
            path: this.$router.currentRoute.path,
            query: {
              order,
              field,
              page: this.page,
              search: this.searchStr
            }
          });
          this.fetchDefinitionLists();
        }
      } catch (e) {
        Message.error(e);
      }
    },
    getModifiedDate(definitionList) {
      // eslint-disable-next-line no-underscore-dangle
      return getHumanDateFromISO(definitionList._updatedAt, false);
    },
    async exportAs(extension = 'csv') {
      let fields = ['name', 'definitionsCount', '_updatedAt'];
      if (this.isOwner(this.tenant)) {
        fields = [...fields, 'teamName'];
      }
      await this.fetchDefinitions();
      exportToList(this.definitions, fields, 'DEFINITION LISTS', extension);
    },
    formatInvalidValue(value) {
      return value === '' || value === null ? `(no value)` : value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.rel_pos {
  position: relative;
}

.headerBox {
  position: relative;
}

.button-row {
  position: absolute;
  top: 90px;
  right: 25px;
}

.round-button {
  font-size: 25px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid $color-border;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  margin: 0;
  text-align: center;
  box-shadow: 0px 2px 2px 2px rgba(116, 116, 117, 0.05);
}

label.round-button {
  line-height: 1.8em;
  background-color: white;
}

.definition-list-name {
  display: block !important;
  flex: 1;
  white-space: nowrap;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-icon-warning-outline {
  font-size: 20px;
  font-weight: bold;
}

::v-deep .inputSearch {
  width: 30vw;
}

::v-deep .title-table {
  font-size: 24px;
  font-weight: 700;
  color: #343c3d;
  margin: 1em 0 0;
}

.UiTable {
  .el-table {
    .ui-table-cell {
      .cell {
        > span {
          &.el-tooltip {
            overflow: initial;
          }
        }
      }
    }
  }
}
</style>
