<template>
  <b-dropdown
    right
    size="sm"
    :variant="selectedUser ? 'dark' : 'outline-dark'"
    boundary="viewport"
    class="impersonate-dropdown"
  >
    <template #button-content>
      <b-icon-eyeglasses />
      <b-icon-x
        v-if="selectedUser"
        @click.stop.capture.prevent="selectedUser = undefined"
      />
    </template>
    <b-dropdown-item
      v-for="user of sortedUsers"
      :key="user.id"
      @click="selectUser(user)"
    >
      {{ user.email }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ImpersonateDropdown',
  data() {
    return {
      isLoading: false,
      selectedUser: undefined
    };
  },
  computed: {
    ...mapState('users', ['error', 'users', 'roles']),
    sortedUsers() {
      if (this.users && this.users.length) {
        return [...this.users].sort((a, b) => (a.email < b.email ? -1 : 1));
      }
      return [];
    }
  },
  watch: {
    users() {
      if (this.users && this.users.length) {
        if (localStorage && localStorage.impersonate !== undefined) {
          this.selectedUser = this.users.find(
            // eslint-disable-next-line eqeqeq
            (u) => u.id == localStorage.impersonate
          );
        }
      }
    },
    selectedUser() {
      if (this.selectedUser) {
        if (localStorage.impersonate !== this.selectedUser.id) {
          localStorage.impersonate = this.selectedUser.id;
        }
        this.getImpersonatedUser(this.selectedUser.id);
      } else {
        localStorage.removeItem('impersonate');
        this.unsetImpersonatedUser();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions('users', [
      'getUsers',
      'getImpersonatedUser',
      'getRoles',
      'setError',
      'unsetImpersonatedUser'
    ]),
    ...mapActions('teams', ['loadTeams']),
    async fetchUsers() {
      this.isLoading = true;
      await this.getUsers();
      this.isLoading = false;
    },
    selectUser(u) {
      this.selectedUser = u;
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }
};
</script>

<style lang="scss">
.impersonate-dropdown {
  .dropdown-toggle {
    border: none;
  }
}
</style>
