<template>
  <Sidebar v-selector :hidden="!sidebarIsVisible">
    <div class="rules_container">
      <h3 class="h5 py-3 d-flex align-items-center">
        <HierarchyIcon class="icon mr-2" />
        {{ $t('product.nav.rules') }}
        <el-button
          type="text"
          class="ml-auto mr-2 rel_pos font-sans-serif"
          :disabled="isReadOnlyMode || product.locked"
          @click="addRule(null)"
        >
          <PlusIcon class="icon" />
          {{ $t('product.add_rule') }}
        </el-button>
        <!--
        Temporarily hidding the import rule button
        <label
          v-if="!isReadOnlyMode"
          for="file"
          class="mr-2 rel_pos ui_input_import_rule"
        >
          <div class="flipY inline mr-1">
            <i class="icon-download-thick-bottom" />
          </div>
          {{ $t("product.import_rule") }}
        </label>
        <input
          id="file"
          ref="fileInput"
          type="file"
          name="file"
          @change="importRule($event)"
        />
        -->
      </h3>
      <el-switch
        v-model="warningsSwitch"
        :active-text="$t('product.show_warnings')"
        class="warningsSwitch"
        @change="toggleWarnings()"
      >
      </el-switch>
      <ul class="rules ui_table_rules">
        <draggable
          v-model="rulesCopy"
          :options="{
            disabled: isReadOnlyMode || product.locked,
            handle: '.dragg'
          }"
          :move="checkMove"
          @end="saveRulesOrder"
        >
          <li
            v-for="rule in rulesCopy"
            :key="rule.id"
            class="rule"
            :class="{ dragg: isNotDefaultRule(rule.id) }"
            :to="rule.id"
            @click="SET_RULE_ID(rule.id)"
          >
            <template v-if="editedRule && editedRule.id === rule.id">
              <input
                ref="editedRuleName"
                v-model="editedRuleName"
                type="text"
                @keypress.enter="saveRuleName(rule)"
              />
              <button class="ui_button_cancel" @click="stopEditingRuleName">
                {{ $t('action.cancel') }}
              </button>
              <button class="ui_button_save" @click="saveRuleName(rule)">
                {{ $t('action.save') }}
              </button>
            </template>

            <router-link
              v-else
              :to="{
                name: 'product-rules-edit',
                params: { productId: getLatestProductId, ruleId: rule.id },
                query: $router.currentRoute.query
              }"
              tag="div"
              class="sidebar-link-wrapper"
            >
              <a class="p-2">
                <el-tooltip
                  v-if="hasCycle(rule)"
                  class="p-1 info-tooltip"
                  effect="light"
                  :content="cycleTipContent"
                  placement="top"
                >
                  <el-button class="rel_pos ui_info_cycle">
                    <i class="fas el-icon-warning"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  v-if="!hasCycle(rule) && !isMatched(rule)"
                  class="p-1 info-tooltip"
                  effect="light"
                  :content="toolTipContent"
                  placement="top"
                >
                  <el-button class="rel_pos ui_info_unmatched">
                    <i class="fas el-icon-warning"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  v-if="!hasCycle(rule) && isMatched(rule) && isEmpty(rule)"
                  class="p-1 info-tooltip"
                  effect="light"
                  :content="emptyTipContent"
                  placement="top"
                >
                  <el-button class="rel_pos ui_info_unmatched">
                    <i class="fas el-icon-warning"></i>
                  </el-button>
                </el-tooltip>
                {{ rule.name }}
              </a>

              <div
                v-if="!isReadOnlyMode && !product.locked"
                class="actions d-flex ml-auto"
              >
                <template v-if="!editedRule">
                  <button
                    v-if="rule.id !== defaultRule.id"
                    title="Duplicate Rule"
                    class="rel_pos ui_button_duplicate"
                    @click="duplicateRule(rule)"
                  >
                    <i class="far fa-copy"></i>
                  </button>
                  <button
                    v-if="rule.id !== defaultRule.id"
                    title="Edit Rule"
                    class="rel_pos ui_button_edit"
                    @click="editRuleName(rule)"
                  >
                    <i class="far fa-edit"></i>
                  </button>
                  <button
                    v-if="rule.id !== defaultRule.id"
                    title="Delete Rule"
                    class="rel_pos ui_button_delete"
                    @click="deleteRule(rule)"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                  <button title @click="toggleLock(rule)">
                    <i
                      :class="
                        rule.is_locked ? 'fas fa-lock' : 'fas fa-lock-open'
                      "
                      class="rel_pos ui_button_lock"
                    ></i>
                  </button>
                </template>
              </div>
              <div v-if="hasWarnings(rule)" class="warnings-count">
                {{ numberOfWarnings(rule) }}
              </div>
            </router-link>
          </li>
        </draggable>
      </ul>
    </div>
  </Sidebar>
</template>

<script>
/* eslint-disable camelcase */
import Vue from 'vue';
import draggable from 'vuedraggable';
import { MessageBox, Message } from 'element-ui';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
// import PencilIcon from "../assets/images/pencil-1.svg";
// import TrashIcon from "../assets/images/bin-1.svg";
import XLSX from 'xlsx';
import { cloneDeep } from 'lodash';
import PlusIcon from '../assets/images/add.svg';
import HierarchyIcon from '../assets/images/hierarchy-2.svg';
import * as api from '../api';
import Sidebar from './Sidebar.vue';
import { getDefaultGraph } from '../util';

const importerNames = ['import_standard', 'import_travellers', 'import_light'];

export default {
  name: 'RulesSidebar',
  components: {
    draggable,
    Sidebar,
    HierarchyIcon,
    PlusIcon
  },
  props: {
    unusedRules: { type: Array, required: true },
    cycleRules: { type: Array, required: true }
  },
  data: () => ({
    editedRule: null,
    editedRuleName: null,
    toolTipContent: 'This rule is not used.',
    emptyTipContent: 'This rule is empty.',
    cycleTipContent: 'There is a cycle involving this rule.',
    warningsSwitch: false,
    rulesCopy: []
  }),
  computed: {
    ...mapState('product', [
      'sidebarIsVisible',
      'product',
      'warnings',
      'requestWarnings'
    ]),
    ...mapState('auth', ['tenant']),
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('auth', ['isGuest']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    defaultRule() {
      let rule = this.product.rules.find(
        (r) => r.id === this.product.main_rule_id
      );
      if (!rule) {
        rule = this.product.rules.find(
          (r) => r.name === 'Cover' || r.name === 'Questions'
        );
      }
      return rule;
    },
    getLatestProductId() {
      return this.product.version.latest;
    },
    theRules() {
      return this.product.rules;
    }
  },
  watch: {
    product() {
      this.rulesCopy = this.getRulesSorted(cloneDeep(this.product.rules));
    }
  },
  created() {
    this.warningsSwitch = this.requestWarnings;
    this.rulesCopy = this.getRulesSorted(cloneDeep(this.product.rules));
  },
  methods: {
    ...mapMutations('product', ['SET_RULE_ID']),
    ...mapActions('product', [
      'fetchProduct',
      'toggleRequestWarnings',
      'refreshProduct'
    ]),
    hasWarnings(rule) {
      return (
        this.warnings &&
        Object.keys(this.warnings).length &&
        this.warnings[rule.name] &&
        this.warnings[rule.name].filter((w) => w.id !== 0).length
      );
    },
    numberOfWarnings(rule) {
      return this.warnings[rule.name].filter((w) => w.id !== 0).length;
    },
    isNotDefaultRule(id) {
      const { defaultRule } = this;
      return defaultRule.id !== id;
    },
    getRulesSorted(rules) {
      const sortedRules = cloneDeep(rules);
      return sortedRules
        .sort((r1, r2) => {
          if (r1.position > r2.position) return 1;
          if (r2.position > r1.position) return -1;
          return 0;
        })
        .map((r, index) => {
          // standarize index
          // eslint-disable-next-line no-param-reassign
          r.position = index;
          return r;
        });
    },
    isValidName(name) {
      const specificationKeys = Object.values(this.product.specification)
        .map((spec) => Object.keys(spec))
        .flat();
      if (
        ['OUT', 'ASK', 'IN', 'Cover', ...specificationKeys]
          .map((k) => k.toUpperCase())
          .includes(name.trim().toUpperCase())
      ) {
        return false;
      }
      return true;
    },
    async addRule(ruleName = null, graph = null) {
      this.stopEditingRuleName();
      let name = null;
      try {
        name =
          ruleName || (await MessageBox.prompt('Name', 'Create Rule')).value;
      } catch {
        return;
      }

      if (!name || name.trim() === '') {
        Message.error(this.$t('rule-editor.rules.no_empty'));
        return;
      }

      if (!this.isValidName(name)) {
        Message.error(this.$t('rule-editor.rules.reserved_word', { name }));
        return;
      }
      for (let i = 0; i < this.product.rules.length; i += 1) {
        if (this.product.rules[i].name === name.trim()) {
          Message.error(this.$t('rule-editor.rules.rule_exists', { name }));
          return;
        }
      }

      this.$emit('changeMade');
      if (graph) {
        this.$emit('addRule', name.trim(), graph);
        return;
      }
      this.$emit('addRule', name.trim());
    },
    importRule(event) {
      this.stopEditingRuleName();
      const [file] = event.target.files;
      if (!file) return;
      const reader = new FileReader();
      reader.onload = function importFile(e) {
        this.importRuleFromFile(e.target.result, file.name);
      }.bind(this);
      reader.readAsArrayBuffer(file);
    },
    async importRuleFromFile(file, file_name) {
      const workbook = XLSX.read(file, { type: 'array' });
      const { specification } = this.product;
      let importer = null;
      for (let i; i < importerNames.length; i += 1) {
        const name = importerNames[i];
        // eslint-disable-next-line no-await-in-loop
        const candidate = await import(`../importers/${name}`);
        if (
          candidate &&
          candidate.canReadWorkbook(workbook, specification) === true
        ) {
          importer = candidate;
          break;
        }
      }
      if (!importer) {
        this.$refs.fileInput.value = '';
        await MessageBox.alert(
          this.$t('product.import.no_matching_importer_found')
        );
        return;
      }

      const name = workbook.SheetNames[0];
      const sheet = workbook.Sheets[name];
      // eslint-disable-next-line prefer-const
      let { meta_data, graph } = importer.graphFromTable(
        this.product.specification,
        sheet,
        file_name
      );
      graph = getDefaultGraph(name, graph);
      this.$refs.fileInput.value = '';
      const productId = this.product.id;
      const { data } = await api.createRule(productId, name);
      const ruleId = data.id;
      await api.updateRule(ruleId, { name, productId, graph, meta_data });
      this.refreshProduct({ productId });
      const params = { ...this.$router.currentRoute.params, ruleId };
      this.$router.push({
        name: 'product-rules-edit',
        params,
        query: this.$router.currentRoute.query
      });
      Message.info(this.$t('product.import.success'));
    },
    async toggleLock(rule) {
      try {
        if (rule.is_locked)
          await MessageBox.confirm(
            `Are you sure you wish to unlock “${rule.name}”`
          );
        // eslint-disable-next-line no-param-reassign
        rule.is_locked = !rule.is_locked;
        // eslint-disable-next-line
        await api.updateRule(rule.id, rule);
        this.$emit('update');
        Message.info(
          `The rule has been ${rule.is_locked ? 'locked' : 'unlocked'}`
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async duplicateRule(rule) {
      try {
        const { value } = await MessageBox.prompt(
          'Name of new rule',
          'Duplicate Rule',
          { inputValue: `${rule.name} (copy)` }
        );
        this.addRule(value, rule.graph);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    editRuleName(rule) {
      if (rule.is_locked) {
        Message.error('The rule is locked');
        return;
      }
      this.editedRule = rule;
      this.editedRuleName = rule.name;
      Vue.nextTick(() => this.$refs.editedRuleName[0].select());
    },
    async saveRuleName(rule) {
      if (rule.is_locked) {
        Message.error('The rule is locked');
        return;
      }
      if (!this.isValidName(this.editedRuleName)) {
        Message.error(
          this.$t('rule-editor.rules.reserved_word', {
            name: this.editedRuleName
          })
        );
        return;
      }
      if (rule.id !== this.defaultRule.id) {
        try {
          const msg =
            'Would you like to rename the rules and all nodes referencing it in the graph or only rename the rule?';
          await MessageBox.confirm(msg, 'Rule rename', {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Rename rule and nodes',
            cancelButtonText: 'Rename rule'
          });
          await this.renameRuleAndReferences(rule.name, this.editedRuleName);
        } catch (action) {
          if (action === 'cancel') {
            await this.renameOnlyTheRule(rule, this.editedRuleName);
          }
          this.stopEditingRuleName();
        }
        this.$emit('changeMade');
      } else {
        Message.error(
          `It is not possible to rename the '${this.defaultRule.name}' node`
        );
      }
    },
    clearUndoStack(graph) {
      if ('undoStack' in graph) {
        // eslint-disable-next-line no-param-reassign
        delete graph.undoStack;
      }
      if (graph.children) {
        graph.children.forEach((child) => this.clearUndoStack(child));
      }
    },
    async renameRuleAndReferences(oldName, newName) {
      const productId = this.product.id;
      await this.fetchProduct({ productId, tenant: this.tenant });
      try {
        const rules = this.product.rules.map((rule) => {
          if (rule.graph) this.clearUndoStack(rule.graph);
          return rule;
        });
        const { data } = await api.renameRule(rules, oldName, newName);
        this.fetchProduct({ productId, tenant: this.tenant });
        await MessageBox.alert(data.message, 'Rule rename');
        this.stopEditingRuleName();
      } catch (err) {
        this.stopEditingRuleName();
      }
    },
    // eslint-disable-next-line
    async renameOnlyTheRule(rule, newName) {
      try {
        if (!rule.graph) {
          // eslint-disable-next-line no-param-reassign
          rule.graph = getDefaultGraph(this.editedRuleName);
        }
        const copy = { ...rule, name: this.editedRuleName };
        if (copy.graph) this.clearUndoStack(copy.graph);
        const { data } = await api.updateRule(this.editedRule.id, copy);
        const productId = this.product.id;
        this.fetchProduct({ productId, tenant: this.tenant });
        await MessageBox.alert(data.message, 'Rule rename');
        this.stopEditingRuleName();
      } catch (err) {
        this.stopEditingRuleName();
      }
    },
    stopEditingRuleName() {
      this.editedRule = null;
      this.editedRuleName = null;
    },
    async deleteRule(rule) {
      try {
        if (rule.is_locked) {
          Message.error('The rule is locked');
          return;
        }
        await MessageBox.confirm(
          `Are you sure you wish to delete “${rule.name}”`
        );
        await api.deleteRule(rule.id);
        Message.info('The rule has been deleted');
        if (this.$router.currentRoute.params.ruleId === rule.id) {
          const rules = this.product.rules.filter((r) => r.id !== rule.id);
          this.product.rules = rules;
          const ruleId = rules.length ? rules[0].id : null;
          this.$router.replace({
            name: 'product-rules-edit',
            params: { productId: this.product.id, ruleId },
            query: this.$router.currentRoute.query
          });
        }
        this.$emit('changeMade');
        this.$emit('update');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    checkMove(evt) {
      if (evt.draggedContext.futureIndex === 0) return false;
      return true;
    },
    async saveRulesOrder(evt) {
      const { newIndex, oldIndex } = evt;

      /* eslint-disable no-param-reassign */
      const rules = this.getRulesSorted(
        this.rulesCopy.map((r) => {
          if (r.position === oldIndex) r.position = newIndex;
          else if (r.position === newIndex && r.position > oldIndex)
            r.position -= 1;
          else if (r.position === newIndex && r.position < oldIndex)
            r.position += 1;
          else if (
            oldIndex < newIndex &&
            r.position < newIndex &&
            r.position > oldIndex
          )
            r.position -= 1;
          else if (
            oldIndex > newIndex &&
            r.position > newIndex &&
            r.position < oldIndex
          )
            r.position += 1;
          return r;
        })
      ).map((r) => r.id);

      try {
        await api.updateRulesOrder(this.product.id, rules, this.tenant);
        Message.success('The order of the rules has been saved');
      } catch (err) {
        Message.error('Could not change the order of the rules');
      }
    },
    isMatched(rule) {
      return this.warnings === null
        ? true
        : this.warnings &&
            this.warnings[rule.name] &&
            !this.warnings[rule.name].find((w) => w.code === 'UnusedRule');
    },
    isEmpty(rule) {
      return (
        this.warnings &&
        this.warnings[rule.name] &&
        this.warnings[rule.name].find(
          (w) => w.id === 0 && w.code === 'EmptyRule'
        )
      );
    },
    hasCycle(rule) {
      // TODO Use rule.id
      return this.cycleRules && this.cycleRules.some((r) => r === rule.name);
    },
    toggleWarnings() {
      this.toggleRequestWarnings();
      const productId = this.product.id;
      this.fetchProduct({ productId, tenant: this.tenant });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

$error-color-dark: #721c24;

h3 {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  margin-bottom: 0;
}

.warningsSwitch {
  margin-bottom: 1rem;
}

.rel_pos {
  position: relative;
}

.rules {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;

    a {
      flex: 1;
    }

    .router-link-active {
      font-weight: bold;
      border-right: 3px solid $color-axa-blue;
    }
  }

  .actions {
    opacity: 0;
  }

  .rule {
    background: white;
    .d-flex {
      align-items: center;
      a {
        display: flex;
        align-items: center;
      }
    }
    .el-icon-warning {
      color: orange;
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .rule:hover {
    .actions {
      opacity: 1;
    }
  }
}

.info-tooltip {
  height: 100%;
}

button,
label[for='file'] {
  cursor: pointer;
  background: white;
  border: 0;
  color: gray;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.move-up {
  margin-top: -10px;
}

label[for='file'] {
  font-weight: 600;
  padding: 12px 20px;
  margin: 0;
}

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.flipY {
  transform: scaleY(-1);
}

.inline {
  display: inline-block;
}

.rules_container {
  overflow: visible;
}

.ui_table_rules {
  max-height: 700px;
  display: flex;
  flex-direction: column;
}

.sidebar-link-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  > a {
    flex: 1;
  }
}

.warnings-count {
  text-align: center;
  font-weight: bold;
  color: #fff;
  background: #ff7979;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  margin-right: 5px;
}
</style>
