<template>
  <div class="RowOperation" @click.stop.prevent>
    <div v-if="isEditing" v-selector class="d-flex justify-content-between">
      <UiCircularButton
        variant="success"
        icon="el-icon-check"
        @click="$emit('command', 'save')"
      />
      <UiCircularButton
        variant="danger"
        icon="el-icon-close"
        @click="$emit('command', 'cancel')"
      />
    </div>
    <div v-else>
      <UiThreeDotsDropDown
        :items="[
          isMember && !isFallback && !isQuestionnaire && !isInitialProduct
            ? {
                command: 'toggleFallbackRelease',
                label: $t('action.toggleFallbackRelease'),
                class: 'ui_menu_toggleFallbackRelease',
                icon: 'fas fa-cog',
                disabled: !isProduction
              }
            : null,
          isMember && !isInitialProduct
            ? {
                command: 'delete',
                label: deleteCommandLabel,
                class: 'ui_menu_delete',
                icon: 'far fa-trash-alt',
                variant: 'danger',
                disabled: isProduction || isActive || isExpired
              }
            : null,
          !isActive
            ? {
                command: 'switch',
                label: $t('product.versions.switchOn'),
                class: 'ui_menu_switch',
                icon: 'fas fa-toggle-on'
              }
            : null,
          showLambdaRetry
            ? {
                command: 'retryLambda',
                label: 'Retry Lambda publication',
                class: 'ui_menu_retryLambda',
                icon: 'fas fa-atom'
              }
            : null
        ]"
        @command="(command) => $emit('command', command)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductReleaseRowOperations',
  props: {
    isInitialProduct: Boolean,
    isFallback: Boolean,
    isEditing: Boolean,
    isActive: Boolean,
    isExpired: Boolean,
    isEditable: { type: Boolean, default: true },
    isMember: Boolean,
    isProduction: Boolean,
    isQuestionnaire: { type: Boolean, default: false },
    isReadOnly: { type: Boolean, default: false },
    showLambdaRetry: { type: Boolean, default: false }
  },
  computed: {
    deleteCommandLabel() {
      if (this.isProduction) return 'Unable to delete a production release';
      if (this.isActive) return 'Unable to delete an active release';
      if (this.isExpired) return 'Unable to delete an expired release';
      return this.$t('action.delete');
    },
    editCommandLabel() {
      if (this.isExpired && this.isQuestionnaire)
        return 'Unable to edit an expired questionnaire';
      return this.$t('action.edit');
    }
  }
};
</script>

<style scoped>
.RowOperation {
  margin-left: auto;
}
</style>
