<template>
  <UiBaseLayout v-selector.view>
    <ComplexityChecker type="blocker"></ComplexityChecker>
    <input ref="fileUpload" type="file" hidden @input="handleSelectedFile" />
    <div class="center-table">
      <UiPaddedArea class="p-0">
        <UiToolbar>
          <div class="generalStatus" data-cy="general-status">
            <div class="failed">
              {{ `${failedTestCount} ${$t('tests.failed')}` }}
            </div>
            <div class="success">
              {{ `${successTestCount} ${$t('tests.success')}` }}
            </div>
          </div>
          <div class="flex-spacer" />
          <b-input-group class="search-group">
            <b-form-input
              v-model="searchStr"
              class="search-input"
              data-cy="tests-search"
              :placeholder="$t(`tests.search_tests`)"
            />
            <b-icon icon="search" class="search-input-icon"></b-icon>
          </b-input-group>
          <div class="flex-spacer" />
          <b-button
            variant="primary"
            :disabled="isReadOnlyMode"
            @click="createNewTest"
          >
            <i class="fas fa-plus mr-2"></i>{{ $t('tests.create_a_test') }}
          </b-button>

          <b-dropdown
            v-if="!isReadOnlyMode"
            variant="outline-primary"
            no-caret
            right
          >
            <template #button-content>
              <b-icon-three-dots />
            </template>

            <b-dropdown-item
              v-if="!noTestsAvailable"
              @click="handleGeneralAction('export')"
            >
              <i class="fas fa-download"></i>
              {{ $t('tests.export_all') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handleGeneralAction('import')">
              <i class="fas fa-upload"></i>
              {{ $t('tests.import') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="areAllSelected"
              @click="handleGeneralAction('deselectAll')"
            >
              <i class="fas fa-check"></i>
              {{ $t('tests.deselect_all') }}
            </b-dropdown-item>
            <b-dropdown-item v-else @click="handleGeneralAction('selectAll')">
              <i class="fas fa-check"></i>
              {{ $t('tests.select_all') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handleGeneralAction('deleteSelected')">
              <i class="fas fa-trash-alt"></i>
              {{ $t('tests.delete_selected') }}
            </b-dropdown-item>
          </b-dropdown>
        </UiToolbar>
      </UiPaddedArea>
      <UiLoadingArea :is-loading="stats.progress > 0 && !stats.error">
        <UiTable
          stripe
          :data="sortedTests"
          :default-sort="{ prop: sortField, order: sortOrder }"
          class="ui_table_tests"
          row-key="id"
          data-cy="tests-table"
          @sort-change="onSort"
        >
          <template #expand="{ row }">
            <div>
              <UiTable
                :data="formatInnerData(row)"
                variant="padded"
                class="innerTable"
                :row-class-name="isFailedTest"
                data-cy="results-table"
              >
                <el-table-column
                  prop="description"
                  :label="$t('tests.output.description')"
                ></el-table-column>
                <el-table-column
                  prop="expected"
                  :label="$t('tests.output.expected')"
                ></el-table-column>
                <el-table-column
                  prop="actual"
                  :label="$t('tests.output.actual')"
                ></el-table-column>
              </UiTable>
            </div>
          </template>

          <el-table-column
            label=""
            width="50"
            align="center"
            data-cy="test-selector"
          >
            <template #default="{ row }">
              <div class="testSelector">
                <input
                  v-model="selectedTests"
                  type="checkbox"
                  :value="row.id"
                />
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="status"
            label="Status"
            sortable="custom"
            width="90"
            align="center"
            data-cy="test-status"
          >
            <template #default="{ row }">
              <div class="testStatus" :class="{ success: row.status }"></div>
            </template>
          </el-table-column>

          <el-table-column
            prop="name"
            label="Name"
            sortable="custom"
            data-cy="test-name"
          >
          </el-table-column>

          <el-table-column prop="" label="" width="70" data-cy="test-actions">
            <template #default="{ row }">
              <UiThreeDotsDropDown
                :items="getTestActionItems(row)"
                data-cy="test-actions-dropdown"
                @command="(command) => handleActionCommand(command)"
              >
              </UiThreeDotsDropDown>
            </template>
          </el-table-column>
        </UiTable>
      </UiLoadingArea>
    </div>
  </UiBaseLayout>
</template>

<script>
import _, { omit } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import { Message, MessageBox } from 'element-ui';
import * as api from '../api';
import { sortByFieldname } from '../helpers';
import ComplexityChecker from '../components/Product/ComplexityChecker.vue';

export default {
  name: 'ProductTests',
  components: {
    ComplexityChecker
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    searchStr: '',
    sortField: 'id',
    sortOrder: 'descending',
    selectedTests: []
  }),
  computed: {
    ...mapGetters('product', ['isReadOnly']),
    ...mapState('productTests', ['tests', 'stats', 'isLoading']),
    ...mapGetters('auth', ['isGuest']),
    ...mapState('route', {
      versionId: (state) => state.query.v
    }),
    emptySearch() {
      return !this.searchStr || this.searchStr.trim().length === 0;
    },
    sortedTests() {
      let result;
      if (this.sortField === 'id' && this.sortOrder === 'descending') {
        result = _.reverse([...this.tests]);
      } else {
        result = sortByFieldname(
          [...this.tests],
          this.sortField,
          this.sortOrder
        );
      }
      if (this.emptySearch) {
        return result;
      }
      return result.filter((t) =>
        t.name.toLowerCase().includes(this.searchStr.toLowerCase())
      );
    },
    failedTestCount() {
      return this.stats.total - this.stats.success;
    },
    successTestCount() {
      return this.stats.success;
    },
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    noTestsAvailable() {
      return (!this.sortedTests || !this.sortedTests.length) && !this.isLoading;
    },
    areAllSelected() {
      return this.tests.length === this.selectedTests.length;
    }
  },
  methods: {
    ...mapActions('productTests', ['fetch']),
    formatInnerData(row) {
      const result = [];
      const outputs = omit(row.definition.output, 'limits');
      Object.entries(outputs).forEach((elm) => {
        result.push({
          description: elm[0],
          expected: `${elm[1]}`,
          actual: `${row.results[elm[0]]}`
        });
      });
      return result;
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    getTestActionItems(row) {
      let actions = [
        {
          command: `edit-${row.id}`,
          label: this.isReadOnlyMode
            ? this.$t('tests.view_test')
            : this.$t('tests.edit_test'),
          class: 'ui_menu_edit',
          icon: this.isReadOnlyMode ? 'el-icon-view' : 'icon-pencil-1',
          disabled: false
        }
      ];

      if (!this.isReadOnlyMode) {
        actions = [
          ...actions,
          {
            command: `duplicate-${row.id}`,
            label: this.$t('tests.copy_test'),
            class: 'ui_menu_edit',
            icon: 'far fa-clone',
            disabled: false
          },
          {
            command: `delete-${row.id}`,
            label: this.$t('tests.delete_test'),
            class: 'ui_menu_delete',
            icon: 'far fa-trash-alt',
            variant: 'danger',
            disabled: false
          }
        ];
      }

      return actions;
    },
    isFailedTest({ row }) {
      if (row.actual !== row.expected) return 'failedTest';
      return '';
    },
    createNewTest() {
      this.$router.push({
        path: 'tests/new',
        query: this.$route.query
      });
    },
    handleActionCommand(command) {
      const [action, testId] = command.split('-');
      switch (action) {
        case 'edit':
          this.$router.push({
            path: `tests/${testId}`,
            query: this.$route.query
          });
          break;
        case 'duplicate':
          this.$router.push({
            path: 'tests/new',
            query: { ...this.$route.query, copy: testId }
          });
          break;
        case 'delete':
          this.deleteTest(testId);
          break;
        default:
          break;
      }
    },
    async handleGeneralAction(action) {
      switch (action) {
        case 'export':
          this.exportTests();
          break;
        case 'import':
          this.$refs.fileUpload.click();
          break;
        case 'selectAll': {
          this.selectedTests = [];
          const filteredTests = this.emptySearch
            ? this.tests
            : this.tests.filter((t) =>
                t.name.toLowerCase().includes(this.searchStr.toLowerCase())
              );
          filteredTests.forEach((test) => {
            this.selectedTests.push(test.id);
          });
          break;
        }
        case 'deselectAll':
          this.selectedTests = [];
          break;
        case 'deleteSelected':
          if (this.selectedTests.length === 0) {
            Message.error(this.$t('tests.no_test_selected'));
            return;
          }
          try {
            await MessageBox.confirm(
              this.$t('tests.delete_test_confirmation-message'),
              this.$t('tests.delete_test_confirmation-title')
            );
          } catch (err) {
            return;
          }
          await api.deleteTests(this.selectedTests);
          this.refresh();
          break;
        default:
          break;
      }
    },
    async deleteTest(testId) {
      try {
        await MessageBox.confirm(
          this.$t('tests.delete_test_confirmation-message'),
          this.$t('tests.delete_test_confirmation-title')
        );
      } catch (err) {
        return;
      }
      await api.deleteTest(testId);
      this.refresh();
    },
    refresh() {
      this.fetch();
    },
    async importTests(file) {
      const { data } = await api.importByType(this.product.id, 'tests', file);
      if (!data.ok) {
        Message.error(this.$t('tests.import_error', { message: data.message }));
        return;
      }

      const { imported, rejected } = data;

      if (imported > 0 && rejected.length === 0) {
        Message.success(
          this.$t('tests.import_message_successful', { nb: imported })
        );
      } else if (imported > 0 && rejected.length > 0) {
        Message.warning(this.$t('tests.import_message_warn', { nb: imported }));
      } else if (imported === 0 && rejected.length > 0) {
        Message.error(
          this.$t('tests.import_message_error', { rejected: rejected.lenth })
        );
      } else {
        Message.info(this.$t('import_message_unknownstate'));
      }

      this.refresh();
    },
    handleSelectedFile(ev) {
      const [file] = ev.target.files;
      this.importTests(file);
    },
    exportTests() {
      api.exportTests(this.product.id, 'tests');
    }
  }
};
</script>

<style lang="scss" scoped>
$redStatus: #e7473a;
$greenStatus: #17da99;
$lightGrey: #fefefe;

.UiToolbar {
  margin-top: 0;
  align-items: center;

  .hidden {
    display: none;
  }

  & ::v-deep .createButton {
    .el-button {
      font-weight: 400;
      padding: 6px 15px;
      text-transform: none;
      background-color: #3139be;
      color: $lightGrey;
      border: none;

      i {
        border-color: $lightGrey;
      }
    }
  }

  .actionsDropdown {
    .el-button {
      border-radius: 5px;
      background-color: $lightGrey;
      color: #464dc5;
      border-color: #cacaf6;
      font-weight: 400;
      padding: 10px 15px;
      text-transform: none;
    }
  }
}

.UiTable.ui_table_tests {
  & ::v-deep .el-table {
    tr {
      td:first-child {
        border-left: 1px solid #ebeef5;
      }

      td:last-child {
        border-right: 1px solid #ebeef5;
      }
    }

    .testSelector {
      input[type='checkbox'] {
        vertical-align: middle;
        transform: scale(1.4);
      }
    }

    .testStatus {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin-left: 25%;
      background-color: $redStatus;

      &.success {
        background-color: $greenStatus;
      }
    }

    .UiTable.innerTable {
      max-width: 600px;
      margin: 0 auto;
      tr {
        &.failedTest {
          background-color: lighten($redStatus, 30%);
        }

        &:hover > td {
          background-color: initial !important;
        }

        td {
          border-left: none;
          border-right: none;

          .cell {
            min-height: 25px;
          }
        }
      }
    }
  }
}

.generalStatus {
  display: flex;
  gap: 8px;
  font-weight: bold;

  div {
    white-space: nowrap;
    vertical-align: middle;

    &:before {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      vertical-align: middle;
      display: inline-block;
      margin-right: 5px;
      content: ' ';
    }

    &.failed::before {
      background-color: $redStatus;
    }

    &.success::before {
      background-color: $greenStatus;
    }
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  .loading-spinner-animation {
    width: 30px;
    height: 30px;
  }
}

.hidden {
  display: none;
}
</style>
