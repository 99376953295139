<template>
  <div>
    <UiStickyNotification ref="notification" />
    <UiLoadingArea :is-loading="isLoading">
      <UiTable
        ref="table"
        :class="`ui_table_${property}`"
        :data="propertiesSorted"
        row-key="name"
        variant="padded"
        :default-sort="{ prop: sortField, order: sortOrder }"
        :expand-row-keys="expandedRows"
        @dblclick="(row) => editRow(propertiesSorted.indexOf(row))"
        @sort-change="onSort"
      >
        <template #expand="{ row, $index }">
          <PropertyMetadata
            :is-read-only="isReadyOnlyMode"
            :property="metadata(row, $index)"
            :default-related-properties="defaultRelatedProperties"
            :selectable-terms="() => selectableTerms(row)"
            :is-dimension="isDimension()"
            :default-property-metadata="propertyMetadata"
            @save="onMetadataSave"
            @remove="onMetadataRemove"
          />
        </template>

        <!-- Name -->
        <el-table-column
          v-if="columns.includes('name')"
          prop="name"
          min-width="500"
          sortable="custom"
          :label="$t('product.name')"
          class-name="left_padded"
        >
          <template #default="{ row, $index }">
            <UiColorPicker
              v-if="columns.includes('color')"
              :toggle-editing="isEditing($index)"
              :value="row.color"
              :palette="colors"
              :handle-change="(v) => (editedRow.value.color = v)"
              :data-cy="`color-picker-${row.name}`"
            />
            <UiInlineEditable
              :is-editable="!isReadyOnlyMode"
              variant="bold"
              :data-cy="`property-technical-name-${row.name}`"
              cy-test="property-technical-name"
              :value="row.name !== placeholderRowName ? row.name : undefined"
              :toggle-editing="isEditing($index)"
              :handle-change="
                (v) => {
                  return filterName(
                    editedRow.value,
                    'name',
                    v,
                    property === 'DIMENSION'
                  );
                }
              "
              @save="saveProperty($index)"
            >
              <template #label>
                <UiLabel
                  variant="bold"
                  :label="row.name"
                  :description="displayName(row, sourceLanguage)"
                />
              </template>
            </UiInlineEditable>
          </template>
        </el-table-column>

        <!-- Definition List -->
        <el-table-column
          v-if="columns.includes('definition_list')"
          prop="definition_list"
          :label="$t('product.definition_list')"
          class-name="left_padded"
          sortable="custom"
          width="250"
        >
          <template #default="{ row, $index }">
            <DefinitionListsDropdown
              v-if="showDefListSelector(row)"
              class="ui_select_definition_list"
              cy-test="property-definition-list"
              :is-editable="!isReadyOnlyMode"
              :is-editing="isEditing($index)"
              :definition-list-id="row.definitionListId"
              :data-cy="`property-definition-list-${row.name}`"
              :definitions="definitions"
              @change="handleChangeDefinitionList"
              @save="saveProperty($index)"
            />
          </template>
        </el-table-column>

        <!-- Value Type -->
        <el-table-column
          v-if="columns.includes('value_type')"
          prop="value_type"
          :label="$t('product.value_type')"
          width="150"
        >
          <template #default="{ row, $index }">
            <el-select
              v-if="isEditing($index)"
              v-model="editedRow.value.valueType"
              :data-cy="`property-type-${row.name}`"
              data-test="property-type"
              placeholder="Type"
              class="ui_item_type"
              @change="saveProperty($index)"
            >
              <el-option
                v-for="type in types"
                :key="type"
                :value="type"
                :label="translateValueType(type)"
              />
            </el-select>
            <span v-else class="ui_item_type">
              {{ row.valueType ? translateValueType(row.valueType) : null }}
            </span>
          </template>
        </el-table-column>

        <!-- Dialect Type -->
        <el-table-column
          v-if="columns.includes('dialect_type')"
          prop="dialect_type"
          :label="$t('product.dialect_type')"
          width="150"
        >
          <template #default="{ row, $index }">
            <el-select
              v-if="isEditing($index)"
              v-model="editedRow.value.dialectType"
              :data-cy="`property-dialect-type-${row.name}`"
              data-test="property-dialect-type"
              placeholder="Dialect type"
              class="ui_item_dialect_type"
              @change="saveProperty($index)"
            >
              <el-option
                v-for="type in dialectTypes"
                :key="type"
                :value="type !== 'NONE' ? type : null"
                :label="$t(`product.field_type.${type}`)"
              />
            </el-select>
            <span v-else class="ui_item_value_type">
              {{ row.dialectType }}
            </span>
          </template>
        </el-table-column>

        <!-- Priority -->
        <el-table-column
          v-if="columns.includes('priority')"
          prop="priority"
          :label="$t('product.priority')"
          width="120"
        >
          <template #default="{ row, $index }">
            <UiInlineEditable
              :is-editable="!isReadyOnlyMode"
              as="number"
              :data-cy="`priority-${row.name}`"
              cy-test="property-priority"
              :label="row.priority || $t('product.not_prioritised')"
              :value="row.priority"
              :toggle-editing="isEditing($index)"
              :handle-change="(v) => (editedRow.value.priority = v)"
              @save="saveProperty($index)"
            />
          </template>
        </el-table-column>

        <!-- Allow multiple -->
        <el-table-column
          v-if="columns.includes('allowMulitple')"
          :label="`${$t('product.allow_multiple')} ${$t('product.per_claim')}`"
          class-name="centered"
          width="200"
        >
          <template #default="{ row, $index }">
            <el-checkbox
              v-if="isEditing($index)"
              v-model="editedRow.value['allowMultiplePerClaim']"
              class="ui_item_multiple"
            />
            <el-checkbox
              v-else
              v-model="row['allowMultiplePerClaim']"
              class="ui_item_multiple"
              disabled
            />
          </template>
        </el-table-column>

        <!-- Operations -->
        <el-table-column v-if="isAdmin(tenant)" width="180">
          <RowOperations
            slot-scope="{ $index }"
            :is-editing="isEditing($index)"
            class="ui_buttons_operations"
            :is-read-only="isReadyOnlyMode"
            @command="(command) => handleRowItemCommand(command, $index)"
          />
        </el-table-column>
      </UiTable>
      <UiPagination
        v-if="propertiesSorted.length"
        ref="paginator"
        :key="Date.now()"
        :current-page.sync="page"
        :page-size="count"
        :total="properties.length"
      />
    </UiLoadingArea>
  </div>
</template>

<script>
import { Message, MessageBox } from 'element-ui';
import { mapGetters, mapActions, mapState } from 'vuex';
import Vue from 'vue';
import { noop } from 'lodash';
import { propertyMetadataName } from '../../domain/model/productMetadata';
import DefinitionListsDropdown from '../DefinitionLists/DefinitionListsDropdown';
import uniqSorter from '../../helpers/uniqSorter';
import PropertyMetadataMixin from '../Metadata/PropertyMetadataMixin';
import RowOperations from '../RowOperations.vue';
import PropertiesMixin from '../PropertiesMixin';
import { sortByFieldname } from '../../helpers';
import PropertyMetadata from '../Metadata/PropertyMetadata.vue';
import DefinitionListMixin from '../DefinitionListMixin';
import I18nMixin from '../I18nMixin';

export default {
  name: 'SharedPropertiesIndex',
  components: {
    RowOperations,
    PropertyMetadata,
    DefinitionListsDropdown
  },
  mixins: [
    DefinitionListMixin,
    PropertiesMixin,
    PropertyMetadataMixin,
    I18nMixin
  ],
  beforeRouteEnter(to, from, next) {
    if (from.params.tenantSlug && !to.params.tenantSlug) {
      return next(`/${from.params.tenantSlug}${to.path}`);
    }
    return next();
  },
  props: {
    defaultRelatedProperties: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      required: true
    },
    groups: {
      type: Array,
      default: () => []
    },
    properties: {
      type: Array,
      default: () => []
    },
    definitions: {
      type: Array,
      default: () => []
    },
    property: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    types: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      count: 10,
      sortField: 'name',
      sortOrder: 'ascending',
      propertiesSorted: [],
      previousSearch: '',
      isCreatingProperty: false,
      expandedRows: null,
      placeholderRowName: 'placeholderRowName',
      newPropertyMetadata: [],
      dialectTypes: ['NONE', 'LIMIT', 'EXCESS', 'DEDUCTIBLE']
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('maintenance', ['isMaintenance']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', { user: 'getUser' }),
    isReadyOnlyMode() {
      return !this.isAdmin(this.tenant.slug) || this.isMaintenance;
    },
    allowExportCSP() {
      return this.isAdmin(this.tenant);
    }
  },
  watch: {
    page() {
      this.stopEditing();
      this.updatePaginatedProperties();
    },
    properties() {
      if (this.previousSearch !== this.search) {
        this.page = 1;
        this.previousSearch = this.search;
      }
      this.updatePaginatedProperties();
    }
  },
  mounted() {
    this.page = 1;
    this.updatePaginatedProperties();
  },
  methods: {
    ...mapActions('sharedProperty', [
      'createSharedProperty',
      'deleteSharedProperty',
      'updateSharedProperty'
    ]),
    async onSort(field, order) {
      try {
        if (order && field) {
          if (order === this.sortOrder && field === this.sortField) {
            return;
          }

          this.sortField = field;
          if (this.sortField === 'groups') this.sortField = 'groupName';
          if (this.sortField === 'definition_list')
            this.sortField = 'definitionName';

          this.sortOrder = order;
          this.updatePaginatedProperties();
        }
      } catch (e) {
        Message.error(e);
      }
    },
    showNotification(type, text, itemIdentifier = null) {
      this.$refs.notification.show(
        type,
        text,
        itemIdentifier,
        this.showItemOnTable
      );
    },
    updatePaginatedProperties() {
      this.propertiesSorted = sortByFieldname(
        this.properties,
        this.sortField,
        this.sortOrder
      ).slice(
        (this.page - 1) * this.count,
        (this.page - 1) * this.count + this.count
      );
      this.isLoading = false;
    },
    metadataByIndex(index) {
      const { metadata = {} } = this.propertyByIndex(index);
      return metadata != null ? metadata : {};
    },
    propertyByIndex(index) {
      return this.propertiesSorted[index];
    },
    async selectableTerms(property) {
      let result = [];
      if (property.type === 'Boolean') {
        result = ['TRUE', 'FALSE'];
      } else if (this.isDimension() && property.definitionListId) {
        await this.fetchItemsForDefinition({
          definitionListId: property.definitionListId,
          tenant: this.tenant
        });
        const items = this.findDefinitionListItemsById(
          property.definitionListId
        );
        result = items.map((t) => t.displayName);
      }
      return uniqSorter(result).map((v) => ({ key: v, label: v }));
    },
    async onMetadataRemove(entry, isNew) {
      if (isNew) return;

      if (this.isCreatingProperty) {
        delete this.newPropertyMetadata[entry.key];
        return;
      }

      const { index, key, previousKey } = entry;

      const { name } = this.propertyByIndex(index);
      const currentMetadata = this.metadataByIndex(index);

      delete currentMetadata[key !== previousKey ? previousKey : key];

      await this.updateMetadata(index, currentMetadata);

      this.showNotification(
        'Success',
        this.$t('product.msg-success-delete-property', { key: name })
      );
    },
    async onMetadataSave(entry, isNew, needToInformUser = true) {
      if (this.isCreatingProperty) {
        this.newPropertyMetadata[entry.key] = entry.value;
        return;
      }

      const { index, key, previousKey, value } = entry;

      if (key.trim() === '') return;

      const { name } = this.propertyByIndex(index);
      const currentMetadata = this.metadataByIndex(index);

      if (key !== previousKey) delete currentMetadata[previousKey];

      currentMetadata[isNew ? propertyMetadataName(key) : key] = value;

      await this.updateMetadata(index, currentMetadata);

      if (needToInformUser) {
        this.showNotification(
          'Success',
          this.$t('product.msg-success-update-property', { key: name })
        );
        this.stopEditing();
      }
    },
    addProperty(data) {
      return this.createSharedProperty({
        propertyData: {
          ...data,
          type: this.property,
          valueType: this.currentType === 'DIMENSION' ? 'Enum' : data.valueType
        },
        propertyParams: { user: { group_id: this.user.group_id } },
        tenant: this.tenant
      });
    },
    editRow(index) {
      if (!this.isAdmin(this.tenant.slug)) {
        return;
      }
      if (this.isEditing(index)) return;
      const entry = this.propertiesSorted[index];
      this.editedRow = {
        index: parseInt(index, 10),
        value: {
          ...entry,
          color: this.colors[entry.color]
            ? this.colors[entry.color]
            : entry.color
        }
      };
    },
    groupName(id) {
      const foundGroup = this.groups.find((team) => team.id === id);
      return foundGroup ? foundGroup.label : '';
    },
    handleRowItemCommand(command, index) {
      switch (command) {
        case 'edit':
          if (this.isCreatingProperty) {
            this.hideCreationRow();
            // eslint-disable-next-line no-param-reassign
            index -= 1;
          }
          this.editRow(index);
          break;
        case 'save':
          if (this.isCreatingProperty) {
            this.handleCreate();
          } else {
            this.saveProperty(index);
          }
          break;
        case 'delete':
          return this.deleteProperty(index);
        case 'cancel':
          this.stopEditing();
          if (this.isCreatingProperty) {
            this.hideCreationRow();
          }
          break;
        default:
          throw new Error(`Unknown command ${command}`);
      }
    },
    handleChangeDefinitionList(v) {
      this.editedRow.value.definitionListId = v || null;
    },
    async saveProperty() {
      if (this.isCreatingProperty) {
        return;
      }

      const currentProperty = this.editedRow.value;
      const { name } = currentProperty;
      const foundPropertyIndex = this.propertiesSorted.findIndex(
        (prop) =>
          prop.name === this.editedRow.value.name &&
          this.editedRow.value.team === prop.team
      );
      if (
        foundPropertyIndex > -1 &&
        foundPropertyIndex !== this.editedRow.index
      ) {
        Message.error(this.$t('product.prop_name_exists'));
      } else {
        let continueWithSave = true;
        if (
          currentProperty.__type === 'OUTPUT' &&
          currentProperty.valueType !== 'text' &&
          currentProperty.definitionListId
        ) {
          await MessageBox.confirm(this.$t('product.output_text_warning'))
            .then(() => {
              currentProperty.definitionListId = null;
            })
            .catch(() => {
              continueWithSave = false;
            });
        }
        if (!continueWithSave) return;

        const { value } = this.editedRow;
        await this.updateProperty(value.id, value)
          .then(() => {
            this.showNotification(
              'Success',
              this.$t('product.msg-success-update-property', { key: name }),
              name
            );
          })
          .catch((e) => {
            Message.error(e);
          });
        this.stopEditing();
      }
    },
    async deleteProperty(index) {
      const { id, name } = this.propertiesSorted[index];
      MessageBox.confirm(this.$t('product.confirm.delete', { name }))
        .then(async () => {
          // noinspection JSValidateTypes
          await this.deleteSharedProperty({ id, tenant: this.tenant });
          if (this.propertiesSorted.length === 0 && this.page > 1) {
            this.page -= 1;
          }
          this.showNotification(
            'Success',
            this.$t('product.msg-success-delete-property', { name })
          );
        })
        .catch(noop);
    },
    isDimension() {
      return this.property === 'DIMENSION';
    },
    scrollToRow(propertyId) {
      for (let k = 0; k < this.$refs.table.$attrs.data.length; k += 1) {
        if (this.$refs.table.$attrs.data[k].id === propertyId) {
          const { offsetTop } =
            this.$refs.table.$el.querySelectorAll('table')[1].rows[k];
          window.scrollTo(
            0,
            offsetTop +
              this.$refs.table.$el.querySelectorAll('table')[0].offsetHeight
          );
        }
      }
    },
    async updateMetadata(index, metadata) {
      this.propertiesSorted[index].metadata = metadata;
      const { id, ...data } = this.propertyByIndex(index);
      await this.updateProperty(id, data);
    },
    async updateProperty(id, property) {
      // noinspection JSValidateTypes
      await this.updateSharedProperty({
        propertyData: { ...property, type: this.property, id },
        tenant: this.tenant
      });
    },
    showItemOnTable(itemIdentifier) {
      const index = this.properties.map((e) => e.name).indexOf(itemIdentifier);
      if (index !== -1) {
        const propPage = Math.floor(index / this.count);
        this.$refs.paginator.currentPageData = propPage + 1;
        Vue.nextTick(() => {
          this.hightlightRow(index % this.count);
        });
      }
    },
    hightlightRow(index) {
      const rows = document.querySelectorAll(
        `.ui_table_${this.property} .el-table__row`
      );
      const row = rows[index];
      if (row) {
        row.className += ' hover-row';
        setTimeout(() => {
          row.className -= ' hover-row';
        }, 2000);
      }
    },
    showDefListSelector(row) {
      return (
        row.__type === 'DIMENSION' ||
        (row.__type === 'OUTPUT' && row.valueType === 'text')
      );
    },
    showCreationRow() {
      if (!this.isCreatingProperty) {
        this.isCreatingProperty = true;
        this.newPropertyMetadata = {};
        const type = this.property;
        const templateRow = {
          name: this.placeholderRowName,
          __type: type
        };
        this.propertiesSorted.unshift(templateRow);
        this.editRow(0);
        this.expandedRows = [this.placeholderRowName];
      }
    },
    hideCreationRow() {
      this.stopEditing();
      this.expandedRows = null;
      this.propertiesSorted.shift();
      this.isCreatingProperty = false;
    },
    async handleCreate() {
      const currentProperty = this.editedRow.value;
      const { name } = currentProperty;

      currentProperty.metadata = this.newPropertyMetadata;

      try {
        if (name.trim() === '' || name === this.placeholderRowName) {
          Message.error(this.$t('product.prop_name_exists', { name }));
          return;
        }
        this.hideCreationRow();
        await this.addProperty(currentProperty)
          .then((property) => {
            this.$emit('property-created');
            this.showNotification(
              'Success',
              this.$t('product.successfull_new_prop'),
              property.name
            );
          })
          .catch((e) => {
            this.newPropertyMetadata = {};
            if (e.code) Message.error(this.$t('product.prop_name_exists'));
            else Message.error(e.message);
          });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        Message.error(e);
      }
    }
  }
};
</script>
