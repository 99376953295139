var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"selector",rawName:"v-selector.view",modifiers:{"view":true}}],staticClass:"container"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h3',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.$t('applications')))]),_vm._v(" "),(false)?_c('el-checkbox',{attrs:{"checked":_vm.showBetaApps},model:{value:(_vm.showBetaApps),callback:function ($$v) {_vm.showBetaApps=$$v},expression:"showBetaApps"}},[_vm._v("\n      Show apps in beta\n    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.nonWidgetapps),function(app){return _c('div',{key:app.path,staticClass:"col-lg-6 d-flex",on:{"click":function($event){return _vm.$emit('currentApp', app)}}},[(app.component)?_c('router-link',{staticClass:"card p-4 mb-4 flex-fill",attrs:{"to":{
          name: `product-app-${app.path}`,
          params: { id: _vm.product.id },
          query: _vm.$router.currentRoute.query
        }}},[_c('h4',{attrs:{"data-cy":`app-${app.name}`}},[_vm._v("\n          "+_vm._s(app.name)+" "),(app.isBeta)?_c('sup',[_vm._v("beta")]):_vm._e()]),_vm._v(" "),_c('p',[_vm._v(_vm._s(app.description))])]):_vm._e(),_vm._v(" "),(!app.component)?_c('a',{staticClass:"card p-4 mb-4 flex-fill",attrs:{"target":"_blank","href":_vm.replaceRouteParams(app.path)}},[_c('h4',{attrs:{"data-cy":`app-${app.name}`}},[_vm._v("\n          "+_vm._s(app.name)+" "),(app.isBeta)?_c('sup',[_vm._v("beta")]):_vm._e()]),_vm._v(" "),_c('p',[_vm._v(_vm._s(app.description))])]):_vm._e()],1)}),0),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h4',[_vm._v(_vm._s(_vm.$t('widgets')))]),_vm._v(" "),_c('i',[_vm._v(_vm._s(_vm.$t('widget-explanation')))])]),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.widgetApps),function(app){return _c('div',{key:app.path,staticClass:"col-lg-6 d-flex",on:{"click":function($event){return _vm.$emit('currentApp', app)}}},[_c('router-link',{staticClass:"card p-4 mb-4 flex-fill",attrs:{"to":{
          name: `product-widget-${app.path}`,
          params: { id: _vm.product.id },
          query: _vm.$router.currentRoute.query
        }}},[_c('h4',[_vm._v(_vm._s(app.name)+" "),(app.isBeta)?_c('sup',[_vm._v("beta")]):_vm._e()]),_vm._v(" "),_c('p',[_vm._v(_vm._s(app.description))])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }